import {
  Container,
  rem,
  Space,
  Stepper,
  useMantineColorScheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconFiles,
  IconFolders,
  IconInfoCircle,
  IconUser,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ProfileService from '../../../../../services/ProfileService';
import type {
  Division,
  Role,
  User,
  UserContractualInfoType,
  UserEmergencyContactType,
  UserPersonalFamily,
  UserPersonalInfo,
  UserPersonalSensitive,
} from '../../../../../types/types';
import AddEmployeeInformationForm from '../AddEmployeeInformationForm/AddEmployeeInformationForm';
import AddEmployeeProfileForm from '../AddEmployeeProfileForm/AddEmployeeProfileForm';
import AddEmployeeSensitiveForm from '../AddEmployeeSensitiveForm';
import AddEmployeeWorkContractForm from '../AddEmployeeWorkContractForm';

type Props = {
  divisions: Division[];
  roles: Role[];
  onClose: () => void;
  refetch: () => void;
  user: User | null;
  userContractualInfo?: UserContractualInfoType | undefined;
  userFamilySituation?: UserPersonalFamily | undefined;
  userPersonalInfo?: UserPersonalInfo | undefined;
  userSensitiveInfo?: UserPersonalSensitive | undefined;
  userEmergencyContact?: UserEmergencyContactType[] | undefined;
  refetchUserEmergencyContact?: () => void;
  refetchUserPersonalInfo?: () => void;
  refetchUserSensitiveInfo?: () => void;
  refetchUserFamilySituation?: () => void;
  refetchUserContractualInfo?: () => void;
  refetchOBInfos?: () => void;
};

export default function AddEmployeeSteppers({
  divisions,
  roles,
  onClose,
  refetch,
  user,
  userContractualInfo,
  userFamilySituation,
  userPersonalInfo,
  userSensitiveInfo,
  userEmergencyContact,
  refetchUserEmergencyContact,
  refetchUserContractualInfo,
  refetchUserFamilySituation,
  refetchUserSensitiveInfo,
  refetchUserPersonalInfo,
  refetchOBInfos,
}: Props) {
  const { t } = useI18n();
  const { access_token } = useAuth();
  const { colorScheme } = useMantineColorScheme();
  const [active, setActive] = useState<number>(0);
  const [creationTypeValue, setCreationTypeValue] = useState('create-employee');
  const [employee, setEmployee] = useState<User | null>(user);
  const [userPersonalInfos, setUserPersonalInfos] =
    useState<UserPersonalInfo | null>(
      userPersonalInfo ? userPersonalInfo : null
    );
  const [familySituation, setFamilySituation] =
    useState<UserPersonalFamily | null>(
      userFamilySituation ? userFamilySituation : null
    );
  const [emergencyContact, setEmergencyContact] = useState<
    UserEmergencyContactType[] | []
  >(userEmergencyContact ? userEmergencyContact : []);
  const [sensitiveInfo, setSensitiveInfo] =
    useState<UserPersonalSensitive | null>(
      userSensitiveInfo ? userSensitiveInfo : null
    );
  const [contractualInfo, setContractualInfo] =
    useState<UserContractualInfoType | null>(
      userContractualInfo ? userContractualInfo : null
    );

  const matches = useMediaQuery('(min-width: 1025px)');

  useEffect(() => {
    if (userPersonalInfo) {
      setUserPersonalInfos(userPersonalInfo);
    }
    if (userFamilySituation) {
      setFamilySituation(userFamilySituation);
    }
    if (userEmergencyContact) {
      setEmergencyContact(userEmergencyContact);
    }
    if (userSensitiveInfo) {
      setSensitiveInfo(userSensitiveInfo);
    }
    if (userContractualInfo) {
      setContractualInfo(userContractualInfo);
    }
    if (user) {
      setEmployee(user);
    }
  }, [
    userPersonalInfos,
    userContractualInfo,
    userFamilySituation,
    userSensitiveInfo,
    userEmergencyContact,
    user,
  ]);

  function onModalClose(): void {
    onClose();
    setEmployee(null);
    setUserPersonalInfos(null);
    setFamilySituation(null);
    setEmergencyContact([]);
    setSensitiveInfo(null);
    setContractualInfo(null);
  }

  const { mutate: validateEmployeeOnBoarding } = useMutation({
    mutationFn: (variables: { userId: string; employee: boolean }) =>
      ProfileService.validateOnBoarding(
        variables.userId,
        variables.employee,
        access_token
      ),
    onSuccess: () => {
      showNotification({
        id: 'validate-onboarding-success',
        title: t('w.success'),
        message: t('success.profileUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      onClose();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: finalValidateEmployeeOnBoarding } = useMutation({
    mutationFn: (variables: { userId: string }) =>
      ProfileService.finalValidationOnBoarding(variables.userId, access_token),
    onSuccess: () => {
      showNotification({
        id: 'validate-onboarding-success',
        title: t('w.success'),
        message: t('success.profileUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      onClose();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-error',
        title: t('w.error'),
        message: t('error.updateFinalOnboarding'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Container size={matches ? '90%' : '100%'}>
      {matches && <Space h={'xl'} />}
      <Stepper
        styles={{
          steps: {
            backgroundColor:
              colorScheme === 'dark'
                ? 'var(--mantine-color-hifivework-4)'
                : 'var(--mantine-color-gray-3)',
            borderRadius: rem(3),
            boxShadow: `0px 0px 6px 0px var(--mantine-color-hifivework-1)`,
            position: 'sticky',
            top: '60px',
            zIndex: 1000,
          },
          stepLabel: {
            fontWeight: 600,
          },
        }}
        active={active}
        onStepClick={setActive}
      >
        <Stepper.Step
          p={matches ? 'sm' : undefined}
          icon={<IconUser size="1.1rem" />}
          label={matches ? t('w.profile') : ''}
        >
          <Space h={'md'} />
          <AddEmployeeProfileForm
            divisions={divisions}
            roles={roles}
            onClose={onModalClose}
            creationTypeValue={creationTypeValue}
            setCreationTypeValue={setCreationTypeValue}
            active={active}
            setActive={setActive}
            refetch={refetch}
            setAddedEmployee={setEmployee}
            employee={employee}
            validateEmployeeOnBoarding={validateEmployeeOnBoarding}
            finalValidateEmployeeOnBoarding={finalValidateEmployeeOnBoarding}
            userPersonalInfo={userPersonalInfos}
            userSensitiveInfo={sensitiveInfo}
          />
        </Stepper.Step>

        <Stepper.Step
          p={matches ? 'sm' : undefined}
          icon={<IconInfoCircle size="1.1rem" />}
          label={matches ? 'Informations personnelles' : ''}
        >
          <Space h={'md'} />
          <AddEmployeeInformationForm
            onClose={onModalClose}
            employee={employee}
            active={active}
            setActive={setActive}
            refetch={refetch}
            setUserPersonalInfo={setUserPersonalInfos}
            setUserFamilySituation={setFamilySituation}
            setUserEmergencyContact={setEmergencyContact}
            userPersonalInfo={userPersonalInfos}
            userFamilySituation={familySituation}
            userEmergencyContact={emergencyContact}
            refetchUserEmergencyContact={refetchUserEmergencyContact}
            refetchUserPersonalInfo={refetchUserPersonalInfo}
            refetchUserFamilySituation={refetchUserFamilySituation}
            validateEmployeeOnBoarding={validateEmployeeOnBoarding}
            refetchOBInfos={refetchOBInfos}
            userSensitiveInfos={userSensitiveInfo}
            finalValidateEmployeeOnBoarding={finalValidateEmployeeOnBoarding}
          />
        </Stepper.Step>
        <Stepper.Step
          p={matches ? 'sm' : undefined}
          icon={<IconFiles size="1.1rem" />}
          label={matches ? 'Documents Administratifs' : ''}
        >
          <Space h={'md'} />
          <AddEmployeeSensitiveForm
            employee={employee}
            refetch={refetch}
            onClose={onModalClose}
            userPersonalInfo={userPersonalInfos}
            userSensitiveInfo={sensitiveInfo}
            setActive={setActive}
            active={active}
            setSensitiveInfo={setSensitiveInfo}
            refetchUserSensitiveInfo={refetchUserSensitiveInfo}
            validateEmployeeOnBoarding={validateEmployeeOnBoarding}
            refetchOBInfos={refetchOBInfos}
            finalValidateEmployeeOnBoarding={finalValidateEmployeeOnBoarding}
          />
        </Stepper.Step>
        {creationTypeValue === 'create-employee' && (
          <Stepper.Step
            p={matches ? 'sm' : undefined}
            icon={<IconFolders size="1.1rem" />}
            label={matches ? t('w.workContract') : ''}
          >
            <Space h={'md'} />
            <AddEmployeeWorkContractForm
              onClose={onModalClose}
              refetch={refetch}
              addedEmployee={employee}
              userContractualInfo={contractualInfo}
              setActive={setActive}
              active={active}
              setContractualInfo={setContractualInfo}
              refetchUserContractualInfo={refetchUserContractualInfo}
              validateEmployeeOnBoarding={validateEmployeeOnBoarding}
              refetchOBInfos={refetchOBInfos}
              finalValidateEmployeeOnBoarding={finalValidateEmployeeOnBoarding}
              userPersonalInfo={userPersonalInfos}
              userSensitiveInfo={sensitiveInfo}
            />
          </Stepper.Step>
        )}
      </Stepper>
    </Container>
  );
}
