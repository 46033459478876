import { type SchedulerProjectData } from '@coderkaine/react-scheduler';
import {
  ActionIcon,
  Flex,
  Group,
  Text,
  Title,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBrandPocket,
  IconCheck,
  IconClockEdit,
  IconHours24,
  IconTableImport,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomUserAvatar from '../../../components/CustomAvatar/CustomUserAvatar';
import CustomMenu from '../../../components/CustomMenu';
import CustomTooltip from '../../../components/CustomTooltip';
import TLPlanning, {
  type TLData,
  type TLGroup,
  TLItem,
  TLVisibleTimes,
} from '../../../components/TLPlanning/TLPlanning';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import usePlanningSSE from '../../../hooks/usePlanningSSE';
import LeaveService from '../../../services/LeaveService';
import LeaveTypeService from '../../../services/LeaveTypeService';
import { isDailyTemplate, isWeeklyTemplate } from '../../../types/guards';
import type {
  Company,
  CompanyDaysWorkedParams,
  DailyTemplate,
  Division,
  LeaveDetails,
  LeaveType,
  PlanningObject,
  PlanningUser,
  WeeklyTemplate,
} from '../../../types/types';
import { DailyWorkParams } from '../../../types/types';
import {
  hasPermission,
  hasRole,
  isAccountant,
} from '../../../utils/authorization';
import {
  convertSession,
  dateString,
  milliToHourMinuteStringUtc,
} from '../../../utils/format';
import goToUserProfile from '../../../utils/goToUserProfile';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { htmlToPdf } from '../../../utils/htmlToPdf';
import {
  ALL,
  AuthorizedReadAllUsersInfo,
  AuthorizedReadPlanning,
  MINE,
  ValidateLeavesAbsences,
} from '../../../variables/BuiltInPermissions';
import { ADMINISTRATOR } from '../../../variables/BuiltInRoles';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import {
  CompanyDaysWorked,
  CompanyUseAssistant,
  CompanyUseN2,
  DailyWork,
} from '../../../variables/ModulesV2';
import {
  getIcon,
  type IconName,
} from '../../company/period-types/components/IconSelector';
import s from '../PlanningView.module.sass';
import PlanningExportExcelMonthPicker from './PlanningExportExcelMonthPicker';
import PlanningLeaveDetails from './PlanningLeaveDetails';
import RejectLeaveRequestForm from './RejectLeaveRequestForm';
import UserLeaveRequestForm from './UserLeaveRequestForm';
dayjs.extend(utc);
dayjs.extend(isoWeek);

type Legend = {
  selected: boolean;
  color: string;
  icon: IconName;
  label: string;
  key: string;
};

type Props = {
  selectedDivisions: string[];
  divisions: Division[];
  planningId: string;
  company: Company;
  selectedTemplate?: DailyTemplate | WeeklyTemplate;
  isRead?: boolean;
};

export default function PlanningScheduler({
  selectedDivisions,
  selectedTemplate,
  divisions,
  planningId,
  company,
  isRead = false,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { getModule, modules } = useModule();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [tlData, setTlData] = useState<TLData>({
    items: [],
    groups: [],
  });
  const [visibleTimes, setVisibleTimes] = useState<TLVisibleTimes>({
    start: dayjs().utc().startOf('month').toDate(),
    end: dayjs().utc().endOf('month').toDate(),
    unit: 'month',
  });
  const [legends, setLegends] = useState<Legend[]>([]);
  const [leaveRequestData, setLeaveRequestData] = useState<
    | {
        date: Date;
        id: string;
        dailyTemplate?: DailyTemplate;
        weeklyTemplate?: WeeklyTemplate;
      }
    | undefined
  >(undefined);
  const isModuleDailyWorkActiveForCompany = getModule(DailyWork)?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;

  const companyDaysWorkedParams = useMemo(() => {
    if (getModule(CompanyDaysWorked)) {
      return getModule(CompanyDaysWorked)?.configuration
        ?.params as CompanyDaysWorkedParams;
    }
  }, [getModule(CompanyDaysWorked)]);

  useQuery({
    enabled: !!companyDaysWorkedParams?.colorIcons?.length,
    queryKey: ['LeaveTypeService.getLeaveTypes', company.id],
    queryFn: () => LeaveTypeService.getLeaveTypes(company.id),
    onSuccess: (data: LeaveType[]) => {
      const _leaveTypes = data?.filter((elt) => !!elt.securityRoles?.length);
      let _legends: Legend[] =
        companyDaysWorkedParams?.colorIcons?.map((item) => ({
          selected: true,
          color: item.color,
          icon: item.icon,
          label: t(`theme.configuration.key.${item.type}`),
          key: item.type,
        })) || [];

      if (!!_leaveTypes?.length) {
        _legends = _legends.concat(
          _leaveTypes.map((item) => ({
            selected: true,
            color: item.color as string,
            icon: item.icon as IconName,
            label: item.name,
            key: item.tag as string,
          }))
        );
      }
      setLegends(_legends);
    },
  });

  const {
    events,
    refetch: refetchPlanning,
    loading,
  } = usePlanningSSE({
    divisionIds: selectedDivisions,
    companyId: company.id,
    start: visibleTimes.start,
    end: visibleTimes.end,
  });

  useEffect(() => {
    if (!!selectedDivisions.length) {
      refetchPlanning();
      setTlData((prevState) => ({
        items: prevState.items.filter((item) => {
          try {
            const idObject = JSON.parse(item.group);
            if (idObject?.divisionId) {
              return selectedDivisions.includes(idObject.divisionId);
            } else {
              return false;
            }
          } catch (_e) {
            return false;
          }
        }),
        groups: prevState.groups.filter((item) => {
          try {
            const idObject = JSON.parse(item.id);
            if (idObject?.divisionId) {
              return selectedDivisions.includes(idObject.divisionId);
            } else {
              return false;
            }
          } catch (_e) {
            return false;
          }
        }),
      }));
    } else {
      setTlData({
        items: [],
        groups: [],
      });
    }
  }, [visibleTimes, selectedDivisions]);

  useEffect(() => {
    if (events?.length && modules?.length) {
      buildPlanning(events.map((item) => item.payload).flat(1));
    }
  }, [events, modules]);

  function isAuthorizedToSeeInfos(showLabel: boolean, userId: string) {
    return (
      showLabel ||
      userId === user.id ||
      hasRole(ADMINISTRATOR, user) ||
      hasPermission(
        { permission: AuthorizedReadAllUsersInfo, scope: ALL },
        user
      ) ||
      hasPermission({ permission: ValidateLeavesAbsences, scope: ALL }, user)
    );
  }

  function getLeaveStatus(
    state: 'VALID' | 'VALIDN1' | 'VALIDN2' | 'REJECTED' | 'WAITING' | undefined
  ) {
    switch (state) {
      case 'VALID':
        return t('leave.status.valid');
      case 'REJECTED':
        return t('leave.status.rejected');
      default:
        return t('leave.status.waiting');
    }
  }

  function getDivisionConcernedName(divisionConcerned: string | undefined) {
    const findDivision = divisions.find(
      (division) => division.id === divisionConcerned
    );
    if (findDivision) {
      return findDivision.name === NO_WORK_UNIT
        ? t('NO_WORK_UNIT')
        : findDivision.name;
    }
  }

  function getLeaveDescription(leave: any): string {
    if (leave) {
      const start: number = leave.period.start;
      const end: number = leave.period.end;
      const leaveTypeName: string = leave.type.name;
      const divisionConcerned: string = leave.divisionConcerned;
      if (leaveTypeName === 'Présence') {
        return `De ${milliToHourMinuteStringUtc(
          start
        )} à ${milliToHourMinuteStringUtc(end)} ${
          Boolean(getDivisionConcernedName(divisionConcerned)) ? '-' : ''
        } ${
          Boolean(getDivisionConcernedName(divisionConcerned))
            ? getDivisionConcernedName(divisionConcerned)
            : ''
        }`;
      }

      return `Du ${dateString(start)} (${convertSession(
        start,
        t
      )}) au ${dateString(end)} (${convertSession(end, t)})`;
    }

    return '';
  }

  function getLeaveBgColor(
    isLeave: boolean,
    state: 'VALID' | 'VALIDN1' | 'VALIDN2' | 'REJECTED' | 'WAITING' | undefined,
    color: string
  ): string {
    if (isLeave) {
      if (state === 'VALID') {
        return color;
      }

      return theme.colors.gray[5];
    } else {
      return color;
    }
  }

  function invertColor(color: string) {
    return (
      '#' +
      (
        '000000' + (0xffffff ^ parseInt(color.substring(1), 16)).toString(16)
      ).slice(-6)
    );
  }

  function getData(
    item: PlanningObject,
    planningUser: PlanningUser
  ): SchedulerProjectData[] {
    return groupePresences(
      (item.blockingPeriods || [])
        .concat(planningUser.periods)
        .filter((leave: LeaveDetails) => leave?.status?.state !== 'REJECTED')
        .map((leave: LeaveDetails) => {
          return {
            id:
              leave?.id ||
              `${leave.type?.id}-${leave.period.start}-${leave.period.end}-${planningUser.user.id}`,
            startDate: dayjs(leave.period.start).utc().toDate(),
            endDate: dayjs(leave.period.end).utc().toDate(),
            occupancy: 0,
            title: isAuthorizedToSeeInfos(
              Boolean(leave.type?.showLabel),
              planningUser.user.id
            )
              ? (leave.type?.name as string)
              : t('w.youAreNotAuthorisedToViewThisInformation'),
            subtitle:
              leave.id &&
              isAuthorizedToSeeInfos(
                Boolean(leave.type?.showLabel),
                planningUser.user.id
              )
                ? getLeaveStatus(leave?.status?.state)
                : '',
            description: getLeaveDescription(leave),
            bgColor: getLeaveBgColor(
              !!leave.id,
              leave.status?.state,
              leave.type?.color as string
            ),
            content: getContent(leave),
            typeName: leave.type?.name as string,
            typeTag: leave.type?.tag as string,
            divisionConcerned: leave.divisionConcerned,
            userDivision: planningUser.user.division.id,
          };
        })
    );
  }

  function groupePresences(
    data: {
      bgColor: string;
      endDate: Date;
      occupancy: number;
      subtitle: string;
      description: string;
      id: string;
      title: string;
      typeName: string;
      divisionConcerned?: string;
      userDivision: string;
      startDate: Date;
      content: null | JSX.Element;
    }[]
  ): SchedulerProjectData[] {
    let groupedByPresenceAndOthers: any = data.reduce(
      (groups, item) => ({
        ...groups,
        // @ts-ignore
        [item.typeName]: [...(groups[item.typeName] || []), item],
      }),
      {}
    );

    const presences: any = [groupedByPresenceAndOthers['Présence']];
    delete groupedByPresenceAndOthers['Présence'];

    let result: any[] = [];
    Object.values(groupedByPresenceAndOthers).forEach((item) => {
      if (item) {
        result = result.concat(item);
      }
    });
    const filteredPresencesByDivision = presences
      .flatMap((item: any) => item)
      .filter((elt: any) => !!elt)
      .filter(
        (presence: any) => presence.divisionConcerned === presence.userDivision
      );
    return result.concat(filteredPresencesByDivision);
  }

  function getContent(leave: any) {
    if (!leave?.type) return null;

    return getIcon({
      color: invertColor(leave.type.color || '#000000'),
      name: leave.type.icon,
      size: 20,
    });
  }

  function filterByPermissionsScoped(
    _items: PlanningObject[]
  ): PlanningObject[] {
    if (!isAccountant(user, company.id)) {
      if (
        !hasPermission(
          [{ permission: AuthorizedReadPlanning, scope: ALL }],
          user
        ) &&
        !hasPermission(
          [{ permission: AuthorizedReadAllUsersInfo, scope: ALL }],
          user
        )
      ) {
        const useN2 = Boolean(getModule(CompanyUseN2)?.active);
        const useAssistant = Boolean(getModule(CompanyUseAssistant)?.active);
        _items = _items.filter(
          (item) =>
            (useN2 && !!item.division.n2 && item.division.n2 === user.id) ||
            (useAssistant &&
              !!item.division.assistant &&
              item.division.assistant === user.id) ||
            item.division.id === user.division.id
        );

        if (
          hasPermission(
            [{ permission: AuthorizedReadPlanning, scope: MINE }],
            user
          )
        ) {
          _items.forEach((item) => {
            item.users = item.users?.filter((elt) => elt.user.id === user.id);
          });
        }
      }
    }

    return _items;
  }

  const buildPlanning = useDebouncedCallback(
    async (items?: PlanningObject[]) => {
      if (items) {
        let _items = cloneDeep(items);
        _items = filterByPermissionsScoped(_items);

        setTlData((prevState) => {
          let tlGroups: TLGroup[] = [...prevState.groups];
          let tlItems: TLItem[] = [...prevState.items];

          _items.forEach((item) => {
            item.users.forEach((_user) => {
              tlGroups = updateTLGroups(item, _user, tlGroups);
              tlItems = updateTLItems(item, _user, tlItems);
            });
          });

          return {
            groups: tlGroups,
            items: tlItems,
          };
        });
      }
    },
    100
  );

  function updateTLGroups(
    item: PlanningObject,
    planningUser: PlanningUser,
    tlGroups: TLGroup[]
  ) {
    const _tlGroups = cloneDeep(tlGroups);
    const isFixedPriceManager = planningUser.user.fixedPriceManager;
    const isActiveForUser = Boolean(
      planningUser.user.paidFeatures?.find(
        (item) => item.name === DailyWork && item.active
      )
    );
    const isDailyWorkActiveForUser =
      isModuleDailyWorkActiveForCompany &&
      (isModuleDailyWorkActiveForAll || isActiveForUser);

    const tlGroup: TLGroup = {
      id: getPlanningDataId(item, planningUser),
      title: (
        <UnstyledButton
          onClick={() => {
            if (isRead) return;
            goToUserProfile(
              user,
              navigate,
              t,
              planningUser.user.id,
              planningUser.user.fullname
            );
          }}
          className={s.ellipsisText}
        >
          <Flex gap="xs" align="center" className={s.ellipsisText}>
            <CustomUserAvatar userId={planningUser.user.id} size={26} />
            <Flex direction="column" className={s.ellipsisText}>
              <Text size="xs" className={s.ellipsisText}>
                {`${planningUser.user.lastname} ${planningUser.user.firstname}`}
              </Text>
              <Text
                size={'10px'}
                c={'dimmed'}
                lh={1}
                className={s.ellipsisText}
              >
                {planningUser.user.division.name === NO_WORK_UNIT
                  ? t('NO_WORK_UNIT')
                  : planningUser.user.division.name}
              </Text>
            </Flex>
            {isDailyWorkActiveForUser &&
              (isFixedPriceManager ? (
                <CustomTooltip label={t('w.fixedPriceManager')}>
                  <IconHours24 color={'var(--mantine-color-hifivework-5)'} />
                </CustomTooltip>
              ) : (
                <CustomTooltip label={t('w.timeEntry')}>
                  <IconClockEdit color={'var(--mantine-color-hifivework-5)'} />
                </CustomTooltip>
              ))}
          </Flex>
        </UnstyledButton>
      ),
    };
    const indexInData = _tlGroups.findIndex((elt) => elt.id === tlGroup.id);
    if (indexInData === -1) {
      _tlGroups.push(tlGroup);
    } else {
      _tlGroups[indexInData] = tlGroup;
    }

    try {
      _tlGroups.sort((a, b) => {
        const aId = JSON.parse(a.id);
        const bId = JSON.parse(b.id);

        const aToTest = `${aId.divisionName?.toLowerCase()}${aId.userFullname?.toLowerCase()}`;
        const bToTest = `${bId.divisionName?.toLowerCase()}${bId.userFullname?.toLowerCase()}`;

        return aToTest.localeCompare(bToTest);
      });
    } catch (_e) {}

    return _tlGroups;
  }

  function updateTLItems(
    item: PlanningObject,
    planningUser: PlanningUser,
    tlItems: TLItem[]
  ): TLItem[] {
    const _tlItems = cloneDeep(tlItems);
    const projectData = getData(item, planningUser);

    projectData.forEach((proDat) => {
      const tlItem: TLItem = {
        id: getPlanningDataId(item, planningUser) + proDat.id,
        group: getPlanningDataId(item, planningUser),
        title: proDat.title,
        start_time: dayjs(proDat.startDate),
        end_time: dayjs(proDat.endDate),
        props: { ...proDat },
      };

      const indexInData = _tlItems.findIndex((elt) => elt.id === tlItem.id);

      if (indexInData === -1) {
        _tlItems.push(tlItem);
      } else {
        _tlItems[indexInData] = tlItem;
      }
    });
    return _tlItems.sort(sortForPlanning);
  }

  function sortForPlanning(a: TLItem, b: TLItem) {
    return getWeight(a.props.typeTag) - getWeight(b.props.typeTag);
  }

  function getWeight(
    typeTag:
      | 'Repos'
      | 'PublicHoliday'
      | 'BlockingPeriod'
      | 'Breakday'
      | 'Work'
      | 'Leave'
  ): number {
    switch (typeTag) {
      case 'Leave':
        return 0;
      case 'BlockingPeriod':
        return 1;
      case 'Breakday':
        return 3;
      case 'Repos':
        return 3;
      case 'PublicHoliday':
        return 4;
      case 'Work':
        return 5;
      default:
        return 6;
    }
  }

  function getPlanningDataId(item: PlanningObject, planningUser: PlanningUser) {
    return JSON.stringify({
      divisionId: item.division.id,
      divisionName: item.division.name,
      userId: planningUser.user.id,
      userFullname: planningUser.user.fullname,
    });
  }

  const { mutate: validateLeaveRequest } = useMutation({
    mutationFn: (leaveId: string) =>
      LeaveService.validateRequest(company.id, leaveId),
    onSuccess: (data) => {
      const { id } = data;
      refetchPlanning();
      modals.closeAll();
      showNotification({
        id: `accept-leave-${id}-request-successful`,
        title: t('w.success'),
        message: t('notification.user.leaveRequestAccepted'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `accept-leave-request-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleValidateLeaveClick(id: string): void {
    validateLeaveRequest(id);
  }

  function handleOpenRejectLeaveModalClick(
    id: string,
    title: string,
    description: string | undefined
  ): void {
    modals.open({
      id: `confirm-reject-leave-${id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('leave.request.decline')}
        </Title>
      ),
      children: (
        <RejectLeaveRequestForm
          id={id}
          title={title}
          description={description}
          refetchPlanningData={refetchPlanning}
        />
      ),
      size: 'lg',
    });
  }

  function handleOpenPlanningDetailsModalClick(
    id: string,
    title: string,
    subtitle: string | undefined,
    description: string | undefined
  ) {
    modals.open({
      id: id,
      title: (
        <Title size={'h3'} component="p">
          {title}
        </Title>
      ),
      children: (
        <PlanningLeaveDetails
          title={title}
          subtitle={subtitle}
          description={description}
          onValidateClick={() => handleValidateLeaveClick(id)}
          onRejectClick={() =>
            handleOpenRejectLeaveModalClick(id, title, description)
          }
        />
      ),
      size: 'lg',
    });
  }

  function onEmptyCellClick(date: Date, id: string) {
    setLeaveRequestData({
      date,
      id: JSON.parse(id).userId,
      dailyTemplate: isDailyTemplate(selectedTemplate)
        ? selectedTemplate
        : undefined,
      weeklyTemplate: isWeeklyTemplate(selectedTemplate)
        ? selectedTemplate
        : undefined,
    });
  }

  function onLeaveTypeFilterClick(item: Legend): void {
    const _legends = [...legends];
    const index = _legends.findIndex(
      (leaveType) => leaveType.label === item.label
    );
    if (index !== -1) {
      _legends[index].selected = !item.selected;
      setLegends(_legends);
    }
  }

  function filterTLData(): TLData {
    let items = cloneDeep(tlData.items);
    if (!!legends?.length) {
      let _legends = legends.filter((item) => item.selected);

      if (!!_legends?.length) {
        items = items.filter((item) => {
          return !!_legends.find(
            (legend) =>
              legend.key === item.props.typeName ||
              legend.label === item.props.typeName ||
              (item.props.typeTag === 'Repos' && legend.key === 'BREAK_DAY') ||
              (item.props.typeTag === 'PublicHoliday' &&
                legend.key === 'PUBLIC_HOLIDAY') ||
              (item.props.typeTag === 'BlockingPeriod' &&
                legend.key === 'BLOCKING_PERIOD')
          );
        });
      } else {
        items = [];
      }
    }

    return {
      groups: tlData.groups,
      items,
    };
  }

  function handleOpenMonthPickerModal() {
    modals.open({
      id: 'choose-month-export-planning-excel',
      title: (
        <Title size={'h3'} component="p">
          Export Excel Planning
        </Title>
      ),
      children: (
        <PlanningExportExcelMonthPicker
          selectedDivisions={selectedDivisions}
          closeModal={() => modals.close('choose-month-export-planning-excel')}
          companyId={company.id}
        />
      ),
    });
  }

  function exportPdf() {
    let periodString = '';
    if (visibleTimes.unit === 'week') {
      periodString = `Semaine ${dayjs(visibleTimes.start).isoWeek()}`;
    } else {
      periodString = dayjs(visibleTimes.start).format('MMMM YYYY');
    }
    htmlToPdf(planningId, t, `Planning-${company?.name}-${periodString}`);
  }

  return (
    <>
      <Group justify="space-between">
        <Group gap={'xs'}>
          {legends.map((legend) => (
            <CustomTooltip key={legend.label} label={legend.label}>
              <ActionIcon
                variant={legend.selected ? 'filled' : 'default'}
                color={legend.selected ? legend.color : theme.colors.gray[1]}
                onClick={() => onLeaveTypeFilterClick(legend)}
              >
                {getIcon({
                  color: legend.selected
                    ? invertColor(legend.color || '#000000')
                    : '#000000',
                  name: legend.icon,
                })}
              </ActionIcon>
            </CustomTooltip>
          ))}
        </Group>

        <CustomMenu
          key={'export-button'}
          buttonLabel={t('w.export')}
          buttonVariant={'filled'}
          leftSection={<IconTableImport />}
          menuLabel={`${t('w.planning')} via :`}
          buttonDisabled={selectedDivisions.length === 0}
          menuItems={[
            {
              label: 'XLSX',
              icon: <IconBrandPocket size={18} />,
              onClick: handleOpenMonthPickerModal,
            },
            {
              label: 'PDF',
              icon: <IconBrandPocket size={18} />,
              onClick: exportPdf,
            },
          ]}
        />
      </Group>
      <TLPlanning
        idPrintPdf={planningId}
        data={filterTLData()}
        visibleTimes={visibleTimes}
        setVisibleTimes={setVisibleTimes}
        onEmptyCellClick={isRead ? () => {} : onEmptyCellClick}
        onItemClick={isRead ? () => {} : handleOpenPlanningDetailsModalClick}
        loading={loading}
      />
      {leaveRequestData && (
        <UserLeaveRequestForm
          key={Date.now()}
          date={leaveRequestData.date}
          userId={leaveRequestData.id}
          dailyTemplate={leaveRequestData.dailyTemplate}
          weeklyTemplate={leaveRequestData.weeklyTemplate}
          onClose={() => setLeaveRequestData(undefined)}
          refresh={refetchPlanning}
        />
      )}
    </>
  );
}
