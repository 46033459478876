import { Grid, TextInput, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';
import LabelText from './LabelText/LabelText';

type Props = {
  form: any;
  disabled: boolean;
};

export default function BankForm({ form, disabled }: Props) {
  const { t } = useI18n();
  const checkMediaQuery = useMediaQuery('(min-width: 1025px)');
  return (
    <>
      {!checkMediaQuery && (
        <Title mb={'lg'} order={3}>
          {t('w.bankInformation')}
        </Title>
      )}
      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label={<LabelText text={'IBAN'} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('iban')}
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label={<LabelText text={'BIC'} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('bic')}
            disabled={disabled}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}
