import {
  ActionIcon,
  Center,
  Container,
  Flex,
  Group,
  MantineColor,
  Paper,
  Skeleton,
  Space,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleCheck,
  IconCircleX,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { ReactNode, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ScreenWrapper from '../../../../core/layouts/components/ScreenWrapper';
import ProfileService from '../../../../services/ProfileService';
import { dateString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { FRONT_URL } from '../../../../variables/GlobalVariables';
import ValidateOauthUserModal from './ValidateOauthUserModal';

export default function ValidateOauthUserScreen() {
  const { t } = useI18n();
  const { linkId } = useParams();
  const { updateUser } = useAuth();
  let location = useLocation();
  const { state } = location;
  const redirect = window.location.pathname;

  const [
    isOpenedValidateOauthUserModal,
    { open: openValidateOauthUserModal, close: closeValidateOauthUserModal },
  ] = useDisclosure(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    data: oauthUserToAccept,
    isLoading,
    isError,
    error,
  } = useQuery({
    enabled: !!linkId && !!state?.accessToken,
    queryKey: ['ProfileService.getAskingLinkProfile', linkId],
    queryFn: () =>
      ProfileService.getAskingLinkProfile(
        linkId as string,
        state?.accessToken as string
      ),
    onError: (err: any) => {
      if (err?.response?.status === 500) {
        showNotification({
          id: 'validate-oauth-user-request-error-already-response',
          title: t('w.error'),
          message: t('error.getAskingLinkProfile.response'),
          color: 'red',
          icon: <IconX />,
        });
      }

      if (err?.response?.status === 401) {
        showNotification({
          id: 'validate-oauth-user-request-error-not-authorized',
          title: t('w.error'),
          message: t('w.unauthorized'),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  const {
    mutate: validateOauthUserLink,
    isLoading: isValidateOauthUserLinkLoading,
  } = useMutation({
    mutationFn: (payload: {
      value: boolean;
      accessToken: string;
      refreshToken: string;
    }) =>
      ProfileService.validateOauthUserLink(
        linkId,
        payload.value,
        payload.accessToken
      ),
    onSuccess: (data, payload) => {
      closeValidateOauthUserModal();
      showNotification({
        id: isAgreed
          ? 'accept-oauth-user-request-successful'
          : 'reject-oauth-user-request-successful',
        title: t('w.success'),
        message: isAgreed
          ? 'Demande de partage acceptée !'
          : 'Demande de partage refusée !',
        color: 'green',
        icon: <IconCheck />,
      });
      setTimeout(() => window.close(), 1500);
      updateUser(data, payload.accessToken, payload.refreshToken, null);
      navigate('/');
    },
    onError: (error) =>
      showNotification({
        id: 'validate-oauth-user-request-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function renderSkeletonsContainer() {
    return (
      <Container>
        <Group p={'md'} align={'center'} justify={'space-between'}>
          <Group>
            <Skeleton height={60} circle />
            <Flex direction={'column'} justify={'flex-start'} gap={'xs'}>
              <Skeleton height={10} width={100} radius="xl" />
              <Skeleton height={10} width={80} radius="xl" />
              <Skeleton height={10} width={120} radius="xl" />
            </Flex>
          </Group>
          <Group>
            <Skeleton height={30} circle />
            <Skeleton height={30} circle />
          </Group>
        </Group>
      </Container>
    );
  }

  function renderActionIcon(
    label: string,
    color: MantineColor,
    agree: boolean,
    icon: ReactNode
  ) {
    return (
      <CustomTooltip label={label}>
        <ActionIcon
          size={'lg'}
          variant="subtle"
          color={color}
          onClick={() => {
            openValidateOauthUserModal();
            setIsAgreed(agree);
          }}
        >
          {icon}
        </ActionIcon>
      </CustomTooltip>
    );
  }

  if (!state?.accessToken || !state?.refreshToken) {
    return (
      <Navigate
        to={`/?redirectUri=${FRONT_URL}${redirect}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <ScreenWrapper
      title={
        oauthUserToAccept?.oauthUserId === null
          ? t(
              'w.confirmRequestToLinkYourProfile',
              oauthUserToAccept?.companyName
            )
          : t('w.confirmRequestToShareYourProfile')
      }
    >
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      {isError && (
        <Container>
          <Center>
            <Title size={'h2'} c={'red'}>
              {
                // @ts-ignore
                error?.response?.status === 500
                  ? t('error.getAskingLinkProfile.response')
                  : t('w.unauthorized')
              }
            </Title>
          </Center>
        </Container>
      )}
      {isLoading && renderSkeletonsContainer()}
      {!isError && oauthUserToAccept && (
        // todo refactor code with AcceptOrRejectRequestWrapper component
        <Container>
          <Paper shadow="xs" p="md" radius={'md'} withBorder>
            <Group justify={'space-between'}>
              <Group>
                {/*<CustomUserAvatar
                  userId={oauthUserToAccept.oauthUserId}
                  isOauth
                  isLinkProfile
                />*/}
                <Flex direction={'column'}>
                  <Text fz={'lg'} fw={'bold'}>{`${
                    oauthUserToAccept.oauthLastname === null
                      ? ''
                      : oauthUserToAccept.oauthLastname
                  } ${
                    oauthUserToAccept.oauthFirstname === null
                      ? ''
                      : oauthUserToAccept.oauthFirstname
                  }`}</Text>
                  <Text mb={'4px'} fz={'xs'} fw={'bold'}>{`Le ${dateString(
                    oauthUserToAccept.created
                  )}`}</Text>
                  <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
                    {`${t('w.accept')} ${t('w.or')} ${t(
                      'w.reject'
                    ).toLowerCase()} ${t('w.request').toLowerCase()} ?`}
                  </Text>
                </Flex>
              </Group>
              <Group>
                {renderActionIcon(
                  t('w.accept'),
                  'green',
                  true,
                  <IconCircleCheck size={40} />
                )}
                {renderActionIcon(
                  t('w.reject'),
                  'red',
                  false,
                  <IconCircleX size={40} />
                )}
              </Group>
            </Group>
          </Paper>
        </Container>
      )}
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <ValidateOauthUserModal
        opened={isOpenedValidateOauthUserModal}
        onClose={closeValidateOauthUserModal}
        isAgreed={isAgreed}
        onClick={() =>
          validateOauthUserLink({
            value: isAgreed,
            accessToken: state.accessToken,
            refreshToken: state.refreshToken,
          })
        }
        isValidateOauthUserLinkLoading={isValidateOauthUserLinkLoading}
      />
    </ScreenWrapper>
  );
}
