import { FileWithPath } from '@mantine/dropzone';
import qs from 'qs';

import {
  OnBoardingStatus,
  OnBoardingType,
  User,
  UserContractualInfoType,
  UserEmergencyContactType,
  UserPayload,
  UserPersonalBreakDay,
  UserPersonalFamily,
  UserPersonalInfo,
  UserPersonalSensitive,
} from '../types/types';
import {
  MS_HIFIVEWORK_PROFILE_ENDPOINT,
  MS_WHITE_LABEL_ID,
} from '../variables/GlobalVariables';
import request from './AxiosClient';

function createCompanyAndAdminUser(access_token: string, data: any) {
  return request
    .post(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/subscribe`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function getCurrentUserProfile(access_token: string) {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/me`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function getUserProfile(
  access_token: string,
  userId: string | undefined
): Promise<User> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function getUserPersonalBreakDay(userId: string | undefined) {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personal-break-day/${userId}`
    )
    .then((res) => res?.data);
}

function updateUserPersonalBreakDay(
  userId: string | undefined,
  userBreakDay: UserPersonalBreakDay[]
) {
  return request
    .patch(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personal-break-day?userId=${userId}`,
      { userBreakDay: userBreakDay }
    )
    .then((res) => res?.data);
}

function getUserHfwProfiles(access_token: string): Promise<User[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/profile/me`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function createUserProfile(companyId: string, data: any) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/employee/${companyId}`,
      data
    )
    .then((res) => res?.data);
}

function updateUserProfile(
  userId: string | undefined,
  data: UserPayload,
  status?: OnBoardingStatus | null
): Promise<User> {
  const formData = new FormData();
  data.lastname && formData.append('lastname', data.lastname);
  data.firstname && formData.append('firstname', data.firstname);
  data.securityRoleId && formData.append('securityRoleId', data.securityRoleId);
  data.divisionId && formData.append('divisionId', data.divisionId);
  data.mail && formData.append('mail', data.mail);
  data.firstManager && formData.append('firstManager', data.firstManager);
  data.secondManager && formData.append('secondManager', data.secondManager);
  data.employment && formData.append('employment', data.employment);
  data.identifiantsCompany &&
    formData.append(
      'identifiantsCompany',
      new Blob([JSON.stringify(data.identifiantsCompany)], {
        type: 'application/json',
      })
    );
  data.valid != null &&
    formData.append(
      'valid',
      new Blob([JSON.stringify(data.valid)], {
        type: 'application/json',
      })
    );
  data.fixedPriceManager != null &&
    formData.append(
      'fixedPriceManager',
      new Blob([JSON.stringify(data.fixedPriceManager)], {
        type: 'application/json',
      })
    );
  data.avatar && formData.append('avatar', data.avatar);
  data.avatar && formData.append('filename', data.avatar.name);
  if (status != null) {
    return request
      .patch(
        `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/${userId}?status=${status}`,
        formData
      )
      .then((res) => res?.data);
  }
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/${userId}`, formData)
    .then((res) => res?.data);
}

function updateUserTermsAcceptance(
  access_token: string,
  userId: string
): Promise<User> {
  const formData = new FormData();
  formData.append(
    'termsAcceptance',
    new Blob([JSON.stringify(true)], {
      type: 'application/json',
    })
  );
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/${userId}`, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function sendEmailToResetPassword(mail: string) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/forgot_password`,
      qs.stringify({ mail: mail, whiteLabelId: MS_WHITE_LABEL_ID })
    )
    .then((res) => res?.data);
}

function getAvatarPath(userId: string): string {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/${userId}/avatar/200/200`;
}

function inviteUser(companyId: string, userId: string) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users/invite?userId=${userId}`
    )
    .then((res) => res?.data);
}

function sendMailToLinkCurrentUserToOauthUser(mail: string) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/profile/link?mail=${encodeURIComponent(
        mail
      )}`
    )
    .then((res) => res?.data);
}
export type AcceptOauthUser = {
  id: string;
  oauthFirstname: string | null;
  oauthLastname: string | null;
  oauthUserId: string | null;
  oauthUsername: string | null;
  profileFirstname: string;
  profileId: string;
  profileLastname: string;
  profileMail: string;
  created: number;
  updated: number;
  companyName: string | null;
};

function getAskingLinkProfile(
  linkId: string,
  access_token: string
): Promise<AcceptOauthUser> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/profile/check/${linkId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function validateOauthUserLink(
  linkId: string | undefined,
  value: boolean,
  access_token: string
): Promise<User> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/profile/link/${linkId}/validate`,
      qs.stringify({ value: value }),
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function invalidateUser(userId: string) {
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/${userId}/invalidate`)
    .then((res) => res?.data);
}

function getUserSensitiveInfo(
  userId: string | undefined
): Promise<UserPersonalSensitive> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}`
    )
    .then((res) => res?.data);
}

function createOrUpdateUserSensitiveInfo(
  userId: string | undefined,
  data: any,
  access_token: string
): Promise<UserPersonalSensitive> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function getUserPersonalFamilySituation(
  userId: string | undefined
): Promise<UserPersonalFamily> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalFamily/${userId}`)
    .then((res) => res?.data);
}

function createOrUpdateUserFamilySituation(
  userId: string | undefined,
  data: any,
  access_token: string
): Promise<UserPersonalFamily> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalFamily/${userId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function getUserPersonalInfo(
  userId: string | undefined
): Promise<UserPersonalInfo> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalInfo/${userId}`)
    .then((res) => res?.data);
}

function createOrUpdateUserPersonalInfo(
  userId: string | undefined,
  data: any,
  access_token: string
): Promise<UserPersonalInfo> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalInfo/${userId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function getUserContractualInfo(
  userId: string | undefined
): Promise<UserContractualInfoType> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/contractualInfo/${userId}`)
    .then((res) => res?.data);
}

function createOrUpdateUserContractualInfo(
  userId: string | undefined,
  data: any,
  access_token: string
): Promise<UserContractualInfoType> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/contractualInfo/${userId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function updateContract(
  userId: string | undefined,
  file: FileWithPath,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/contractualInfo/${userId}/contract`,
      { fileName: file.name, file: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function getUserContract(userId: string) {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/contractualInfo/${userId}/contract`;
}

function updateResidencePermit(
  userId: string | undefined,
  file: FileWithPath,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}/residencePermit`,
      { fileName: file.name, file: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}
function getResidencePermit(userId: string | undefined) {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}/residencePermit`;
}

function updateSecuritySocial(
  userId: string | undefined,
  file: FileWithPath,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}/securitySocial`,
      { fileName: file.name, file: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}
function getSecuritySocial(userId: string | undefined) {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}/securitySocial`;
}

function updateIdentityCard(
  userId: string | undefined,
  file: FileWithPath,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}/identityCard`,
      { fileName: file.name, file: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}
function getIdentityCard(userId: string | undefined) {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/personalSensitive/${userId}/identityCard`;
}

function getUserEmergencyContact(
  userId: string | undefined
): Promise<UserEmergencyContactType[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/emergencyContact/${userId}`)
    .then((res) => res?.data);
}

function createOrUpdateUserEmergencyContact(
  userId: string | undefined,
  data: any,
  access_token: string
): Promise<UserEmergencyContactType[]> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/emergencyContact/${userId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function deleteUserEmergencyContact(
  userId: string | undefined,
  emergencyContactId: string | undefined | null,
  access_token: string
): Promise<UserEmergencyContactType[]> {
  return request
    .delete(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/emergencyContact/${userId}/${emergencyContactId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function validateOnBoarding(
  userId: string,
  employee: boolean,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/onBoarding/${userId}/employee/validate?employee=${employee}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function finalValidationOnBoarding(
  userId: string | undefined,
  access_token: string
) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/onBoarding/${userId}/final-validation`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function employeeOnBoardingSignUp(userId: string) {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/onBoarding/${userId}/employee/sign-up`
    )
    .then((res) => res?.data);
}

function employeeOnBoardingInfos(
  userId: string | undefined
): Promise<OnBoardingType> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/user/onBoarding/${userId}`)
    .then((res) => res?.data);
}

const ProfileService = {
  createCompanyAndAdminUser,
  getCurrentUserProfile,
  getUserProfile,
  getUserPersonalBreakDay,
  updateUserPersonalBreakDay,
  getUserHfwProfiles,
  createUserProfile,
  updateUserProfile,
  updateUserTermsAcceptance,
  sendEmailToResetPassword,
  getAvatarPath,
  inviteUser,
  sendMailToLinkCurrentUserToOauthUser,
  getAskingLinkProfile,
  validateOauthUserLink,
  invalidateUser,
  getUserSensitiveInfo,
  createOrUpdateUserSensitiveInfo,
  getUserPersonalFamilySituation,
  getUserPersonalInfo,
  getUserContractualInfo,
  createOrUpdateUserContractualInfo,
  updateContract,
  getUserContract,
  createOrUpdateUserFamilySituation,
  createOrUpdateUserPersonalInfo,
  getSecuritySocial,
  updateSecuritySocial,
  getResidencePermit,
  updateResidencePermit,
  getIdentityCard,
  updateIdentityCard,
  getUserEmergencyContact,
  createOrUpdateUserEmergencyContact,
  deleteUserEmergencyContact,
  validateOnBoarding,
  employeeOnBoardingSignUp,
  employeeOnBoardingInfos,
  finalValidationOnBoarding,
};

export default ProfileService;
