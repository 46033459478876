import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GmaIcon } from '../../../../components/icons/GmaIcon';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import GererMesAffairesService from '../../../../services/GererMesAffairesService';
import {
  FRONT_URL,
  GMA_HFW_COLLABORATOR_CLIENT_ID,
  MS_WHITE_LABEL_ID,
} from '../../../../variables/GlobalVariables';
import type { ProviderProps } from '../../Login';
import useGmaLogin from './hooks/useGmaLogin';

export default function GmaLogin({
  setOpenProfileChoice,
  setAuthInfo,
}: ProviderProps) {
  const { t } = useI18n();
  const { updateUser } = useAuth();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const { login, error, loginResponse } = useGmaLogin(code);

  useEffect(() => {
    if (code) {
      login();
    }
  }, [code]);

  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.users?.length) {
        if (loginResponse.users.length > 1) {
          setOpenProfileChoice(true);
          setAuthInfo(loginResponse);
        } else {
          updateUser(
            loginResponse.users[0],
            loginResponse.access_token,
            loginResponse.refresh_token,
            null
          );
        }
      }
    }
  }, [loginResponse]);
  useEffect(() => {
    if (error.isError) {
      showNotification({
        id: 'gma-login-error',
        title: t('w.authentificationError'),
        message: error.details.response.data.error_description,
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [error]);

  function onClick() {
    window.location.replace(
      GererMesAffairesService.getCollaboratorOpenidMailConnexionLink(
        GMA_HFW_COLLABORATOR_CLIENT_ID,
        FRONT_URL
      )
    );
  }

  if (MS_WHITE_LABEL_ID !== 'HIFIVEWORK') {
    return null;
  }

  return (
    <Button
      variant="default"
      styles={() => ({
        root: {
          borderRadius: 100,
          width: 300,
          height: 42,
          padding: 0,
        },
        label: {
          fontWeight: 'bold',
        },
      })}
      leftSection={<GmaIcon />}
      onClick={onClick}
    >
      {t('login.with.gma')}
    </Button>
  );
}
