import { Divider, Flex, rem, Title, UnstyledButton } from '@mantine/core';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomCompanyAvatar from '../../../components/CustomAvatar/CustomCompanyAvatar';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import useCompany from '../../../hooks/useCompany';
import { Link } from '../../../types/types';
import { hasPermission, isAccountant } from '../../../utils/authorization';
import { isWhiteLabel } from '../../../utils/isWhiteLabel';
import { isHiFiveWorkProject } from '../../../utils/projects';
import {
  ALL,
  ConfigureAccountant,
  ConfigureBlockingPeriods,
  ConfigureCompany,
  ConfigureEmployees,
  ConfigureFeatures,
  ConfigureLeaveTypes,
  ConfigurePayment,
  ConfigurePermissions,
  ConfigurePublicHolidaysCompanyLeaves,
  ConfigureShuttleSheetTypes,
  ConfigureTools,
  ConfigureWorkUnit,
  TEAM,
} from '../../../variables/BuiltInPermissions';
import { ShuttleSheet } from '../../../variables/ModulesV2';
import UserMenu from '../UserMenu/UserMenu';
import SubLinksMenu from './components/SubLinksMenu';
import classes from './DoubleNavBar.module.sass';

type Props = {
  linksData: Link[];
};

export function DoubleNavbar({ linksData }: Props) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { t } = useI18n();
  const navigate = useNavigate();
  const { id: companyId, company } = useCompany(user);
  const { getModule } = useModule();
  const isShuttleSheetActiveModule = getModule(ShuttleSheet)?.active;

  const settingsLinks = useMemo(
    () => [
      {
        label: t('w.employees'),
        link: `/company/${companyId}/employees`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission({ permission: ConfigureEmployees, scope: ALL }, user),
      },
      {
        label: company?.divisionLabel ? company.divisionLabel : t('w.units'),
        link: `/company/${companyId}/work-units`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission(
            [
              { permission: ConfigureWorkUnit, scope: ALL },
              { permission: ConfigureWorkUnit, scope: TEAM },
            ],
            user
          ),
      },
      {
        label: t('w.counterTypes'),
        link: `/company/${companyId}/counter-types`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission({ permission: ConfigureCompany, scope: ALL }, user),
      },
      {
        label: t('w.typesOfPeriod'),
        link: `/company/${companyId}/period-types`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission({ permission: ConfigureLeaveTypes, scope: ALL }, user),
      },
      {
        label: t('w.periods'),
        link: `/company/${companyId}/periods`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission(
            [
              { permission: ConfigureBlockingPeriods, scope: ALL },
              { permission: ConfigurePublicHolidaysCompanyLeaves, scope: ALL },
            ],
            user
          ),
      },
      {
        label: t('w.publicHolidays'),
        link: `/company/${companyId}/public-holidays-and-leave`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission(
            { permission: ConfigurePublicHolidaysCompanyLeaves, scope: ALL },
            user
          ),
      },
      {
        label: t('w.shuttleSheetTypes'),
        link: `/company/${companyId}/shuttle-sheet-type`,
        isDisplayed:
          isShuttleSheetActiveModule &&
          (isAccountant(user, companyId) ||
            hasPermission(
              { permission: ConfigureShuttleSheetTypes, scope: ALL },
              user
            )),
      },
      {
        label: t('w.payrollOfficer'),
        link: `/company/${companyId}/payroll-officer`,
        isDisplayed:
          user.company.id === companyId &&
          hasPermission({ permission: ConfigureAccountant, scope: ALL }, user),
      },
      {
        label: t('w.marketPlace'),
        link: `/company/${companyId}/market-place`,
        isDisplayed:
          (isAccountant(user, companyId) ||
            hasPermission(
              [
                { permission: ConfigureFeatures, scope: ALL },
                { permission: ConfigureTools, scope: ALL },
              ],
              user
            )) &&
          !isWhiteLabel(),
      },
      {
        label: t('w.features'),
        link: `/company/${companyId}/features`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission({ permission: ConfigureFeatures, scope: ALL }, user),
      },
      {
        label: t('w.userRoles'),
        link: `/company/${companyId}/roles`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission({ permission: ConfigurePermissions, scope: ALL }, user),
      },
      {
        label: t('w.billing'),
        link: `/company/${companyId}/billing`,
        isDisplayed:
          (isAccountant(user, companyId) ||
            hasPermission(
              { permission: ConfigurePayment, scope: ALL },
              user
            )) &&
          isHiFiveWorkProject(),
      },
      {
        label: t('w.settings'),
        link: `/company/${companyId}/settings`,
        isDisplayed:
          isAccountant(user, companyId) ||
          hasPermission({ permission: ConfigureCompany, scope: ALL }, user),
      },
    ],
    [user, companyId, company?.divisionLabel, isShuttleSheetActiveModule]
  );

  const links = settingsLinks
    .filter((item) => item.isDisplayed)
    .map((item) => (
      <UnstyledButton
        className={classes.link}
        style={{ height: rem(38), lineHeight: rem(38) }}
        data-active={pathname === item.link || undefined}
        key={item.label}
        onClick={() => {
          navigate(item.link);
        }}
      >
        {item.label}
      </UnstyledButton>
    ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={classes.aside} style={{ flex: `0 0 ${rem(60)}` }}>
          {linksData.map((item) => (
            <div key={item.label as string}>
              <SubLinksMenu item={item} pathname={pathname} />
              {item.divider && <Divider m={'xs'} />}
            </div>
          ))}
          <Flex h={'100%'} justify={'flex-end'} direction={'column'}>
            <UserMenu isDoubleNavBar={true} />
          </Flex>
        </div>
        <div className={classes.main}>
          <Flex align={'center'} gap={'xs'} ml={'md'} w={'80%'} mt={'md'}>
            <CustomCompanyAvatar companyId={companyId} />
            <Title
              size={'h6'}
              className={classes.title}
              style={{
                borderBottom: `${rem(1)} solid gray.3`,
              }}
            >
              {company?.name}
            </Title>
          </Flex>
          {links}
        </div>
      </div>
    </nav>
  );
}
