import { useAuth } from '../../../contexts/AuthProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import { hasPermission } from '../../../utils/authorization';
import {
  ALL,
  ValidateLeavesAbsences,
} from '../../../variables/BuiltInPermissions';
import { DailyWork } from '../../../variables/ModulesV2';

export default function useDisplayTeamActivities(): boolean {
  const { getModule } = useModule();
  const { user } = useAuth();

  return Boolean(
    getModule(DailyWork)?.active &&
      hasPermission([{ permission: ValidateLeavesAbsences, scope: ALL }], user)
  );
}
