import { Grid, Group, Text } from '@mantine/core';

import { useI18n } from '../../contexts/I18nProvider';
import classes from './SignUp.module.sass';

export default function SignUpInfo() {
  const { t } = useI18n();

  return (
    <Grid justify={'center'} align={'center'} className={classes.info}>
      <Grid.Col span={6}>
        <Group h={'100vh'}>
          <Text fz="md" fw={600}>
            {t('w.youAreGoingToReceiveAnEmail')}
          </Text>
        </Group>
      </Grid.Col>
    </Grid>
  );
}
