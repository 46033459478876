import { Avatar } from '@mantine/core';
import type { MantineSize } from '@mantine/core/lib/core';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import OauthServiceMS from '../../services/OauthServiceMS';
import ProfileService from '../../services/ProfileService';
import s from './CustomUserAvatar.module.sass';

type Props = {
  userId: string | null;
  size?: MantineSize | (string & {}) | number;
  isOauth?: boolean;
  isLinkProfile?: boolean;
};

export default function CustomUserAvatar({
  userId,
  size = 60,
  isOauth = false,
  isLinkProfile = false,
}: Props) {
  const { access_token } = useAuth();

  const { data } = useQuery({
    enabled: isOauth,
    queryKey: ['OauthServiceMS.getOauthUser', access_token],
    queryFn: () => OauthServiceMS.getOauthUser(access_token),
  });

  const oauthUser = useMemo(() => {
    if (!data) {
      return null;
    }
    return data;
  }, [data]);
  return (
    <Avatar
      size={size}
      src={
        isOauth
          ? OauthServiceMS.getAvatarPath(isLinkProfile ? userId : oauthUser?.id)
          : userId !== null
          ? ProfileService.getAvatarPath(userId)
          : ''
      }
      radius={60}
      className={s.root}
    />
  );
}
