import { Select, SelectProps, SimpleGrid, Switch, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useDebouncedCallback } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import s from '../../features/activities/my-activities/MyActivities.module.sass';
import DailyWorkStatusBadge from '../../features/activities/team-activities/components/DailyWorkStatusBadge';
import useDisplayTeamActivities from '../../hooks/accessChecking/activities/useDisplayTeamActivities';
import DailyWorkService, {
  UserPresenceHelper,
} from '../../services/DailyWorkService';
import type {
  DailyWorkDetails,
  DailyWorkDetailsStatus,
  LeaveDetails,
} from '../../types/types';
import handleErrorMessage from '../../utils/handleErrorMessage';
import css from './MonthEventScheduler.module.sass';

export declare type SchedulerDailyWorkHeadInfoCellProps = {
  dailyWork: DailyWorkDetails;
  refetchDailyWorksList: () => void;
};

export default function SchedulerDailyWorkHeadInfoCell({
  dailyWork,
  refetchDailyWorksList,
}: SchedulerDailyWorkHeadInfoCellProps) {
  const { t } = useI18n();
  const isEditable: boolean = dailyWork.status === 'WAITING';
  const timeInputRef = useRef<HTMLInputElement>(null);
  const [overtime, setOvertime] = useState<number>(dailyWork.overtime);
  const [status, setStatus] = useState<string>(dailyWork.status);
  const [workingTimeDay, setWorkingTimeDay] = useState<number>(
    dailyWork.workingTimeDay
  );
  const canValidateActivities = useDisplayTeamActivities();

  const renderStatusSelectOption: SelectProps['renderOption'] = ({
    option,
    checked,
  }) => (
    <DailyWorkStatusBadge
      status={option.value}
      smallText={true}
      withCheck={checked}
    />
  );

  const { mutate: updateOvertime } = useMutation({
    mutationFn: (_overtime: number) =>
      DailyWorkService.updateUserDailyWork(
        dailyWork.user.id,
        UserPresenceHelper.toUserPresence({
          ...dailyWork,
          overtime: _overtime,
        })
      ),
    onSuccess: () => {
      refetchDailyWorksList();
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  const { mutate: updateDayWorked } = useMutation({
    mutationFn: (dayWorked: boolean) => {
      if (dayWorked) setWorkingTimeDay(1439);
      else setWorkingTimeDay(0);

      const leave = {
        period: {
          start: dayjs(dailyWork.date).startOf('day').utc(true).unix() * 1000,
          end: dayjs(dailyWork.date).endOf('day').utc(true).unix() * 1000,
        },
      };

      const leaves: LeaveDetails[] = [];
      if (dayWorked) leaves.push(leave);

      const dailyWorkUpdate: DailyWorkDetails = {
        ...dailyWork,
        status: 'WAITING',
        leaves: leaves,
      };

      return DailyWorkService.updateUserDailyWork(
        dailyWork.user.id,
        UserPresenceHelper.toUserPresence(dailyWorkUpdate)
      );
    },
    onSuccess: () => {
      refetchDailyWorksList();
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  const { mutate: updateStatus } = useMutation({
    mutationFn: (status: DailyWorkDetailsStatus) => {
      setStatus(status);
      const dailyWorkUpdate: DailyWorkDetails = {
        ...dailyWork,
        status: status,
      };
      return DailyWorkService.updateUserDailyWork(
        dailyWork.user.id,
        UserPresenceHelper.toUserPresence(dailyWorkUpdate)
      );
    },
    onSuccess: () => {
      refetchDailyWorksList();
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  function onOverTimeChange(newOvertime: string) {
    const [hour, minute] = newOvertime.split(':');
    const _overtime = dayjs
      .duration({ hours: Number(hour), minutes: Number(minute) })
      .asMinutes();
    setOvertime(_overtime);

    handleAutoSaveOvertime(_overtime);
  }

  const handleAutoSaveOvertime = useDebouncedCallback(
    async (_overtime: number) => {
      updateOvertime(_overtime);
    },
    1000
  );

  return (
    <div
      className={`
                    ${css['scheduler-header-row-scrollable-cells-cell']}
                    ${css['scheduler-header-row-scrollable-cells-cell-workInfo']}`}
      key={dayjs(dailyWork.date).get('date')}
    >
      <div>
        <Select
          disabled={!canValidateActivities}
          data={[
            { value: 'WAITING', label: t('dailyWork.status.state.WAITING') },
            { value: 'VALID', label: t('dailyWork.status.state.VALID') },
          ]}
          classNames={{
            input: css['scheduler-status-input'],
            root: css['scheduler-status-root'],
            wrapper: css['scheduler-status-wrapper'],
            dropdown: css['scheduler-status-dropdown'],
            option: css['scheduler-status-dropdown'],
          }}
          defaultValue={status}
          allowDeselect={false}
          renderOption={renderStatusSelectOption}
          variant={'filled'}
          radius="xl"
          size="xs"
          styles={{
            input: {
              backgroundColor: status === 'VALID' ? '#8ce99a' : '#FCF0E0',
            },
          }}
          onChange={(_value) => updateStatus(_value! as DailyWorkDetailsStatus)}
        />
      </div>
      <SimpleGrid
        cols={2}
        spacing="xs"
        verticalSpacing="xs"
        style={{
          textAlign: 'left',
          margin: '4px 2px',
          alignItems: 'center',
        }}
        styles={{
          root: { gap: '4px 0px' },
        }}
      >
        {!dailyWork.user.fixedPriceManager ? (
          <>
            <Text size="xs">{t('w.htrav')}</Text>
            <Text size="xs" fw="bold">
              {dayjs
                .duration(dailyWork.workingTimeDay, 'minutes')
                .format('HH:mm')}
            </Text>
            <Text size="xs">{t('w.hsupp')}</Text>
            <div>
              {isEditable ? (
                <TimeInput
                  ref={timeInputRef}
                  size="xs"
                  value={dayjs.duration(overtime, 'minutes').format('HH:mm')}
                  onChange={(event) =>
                    onOverTimeChange(event.currentTarget.value)
                  }
                  classNames={{
                    input: cn(s.input, !!overtime && s.hasOvertimeInput),
                  }}
                />
              ) : (
                <Text size="xs" fw="bold">
                  {dayjs.duration(overtime, 'minutes').format('HH:mm')}
                </Text>
              )}
            </div>
          </>
        ) : (
          <>
            <Text size="xs" mt="xs">
              {t('w.jrtrav')}
            </Text>
            <Switch
              mt="xs"
              checked={workingTimeDay > 0}
              onChange={(event) => updateDayWorked(event.currentTarget.checked)}
            />
          </>
        )}
      </SimpleGrid>
    </div>
  );
}
