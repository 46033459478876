import { Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo } from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import DailyWorkService from '../../../services/DailyWorkService';
import ProfileService from '../../../services/ProfileService';
import type {
  DailyTemplate,
  DailyWorkRequest,
  PeriodTemplate,
  UserDailyWork,
  WeeklyTemplate,
} from '../../../types/types';
import { dateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import LeavesRequestFormModal from '../../home/components/leaves/LeavesRequestFormModal';
import DailyWorkConflictedModal from './DailyWorkConflictedModal';
dayjs.extend(utc);

type Props = {
  date: Date;
  userId: string;
  onClose: () => void;
  refresh: () => void;
  dailyTemplate?: DailyTemplate;
  weeklyTemplate?: WeeklyTemplate;
};

export default function UserLeaveRequestForm({
  date,
  userId,
  onClose,
  refresh,
  dailyTemplate,
  weeklyTemplate,
}: Props) {
  const { t } = useI18n();
  const { access_token } = useAuth();

  const { data: userProfile } = useQuery({
    queryKey: ['ProfileService.getUserProfile', userId],
    queryFn: () => ProfileService.getUserProfile(access_token, userId),
    onError: close,
  });

  const { data } = useQuery({
    queryKey: [
      'DailyWorkService.getUserDailyWork',
      userId,
      dayjs(date).utc().month() + 1,
      dayjs(date).utc().year(),
    ],
    queryFn: () =>
      DailyWorkService.getUserDailyWork(
        userId,
        dayjs(date).utc().month() + 1,
        dayjs(date).utc().year()
      ),
  });

  const userDailyWork: UserDailyWork | null = useMemo(() => {
    if (!data) return null;

    if (userProfile) {
      return {
        userId: userId,
        status: 'VALID',
        employeeName: userProfile.fullname,
        dailyWorks: data,
      };
    } else {
      return null;
    }
  }, [data]);

  useEffect(() => {
    if (dailyTemplate) {
      createDailyWorksBasedOnDailyTemplate();
    }
  }, [dailyTemplate, date, userProfile, userDailyWork]);

  useEffect(() => {
    if (weeklyTemplate) {
      createDailyWorksBasedOnWeeklyTemplate();
    }
  }, [weeklyTemplate, date, userProfile, userDailyWork]);

  function templateToString(periods: PeriodTemplate[]) {
    const templatePeriodString: string[] = [];
    periods.forEach((item) => {
      templatePeriodString.push(`${item.startDate}-${item.endDate}`);
    });

    return templatePeriodString.join(',');
  }

  function createDailyWorksBasedOnDailyTemplate() {
    if (!!userDailyWork && !!userProfile) {
      const infoDatePlanning = userDailyWork?.dailyWorks.find(
        (item) =>
          dayjs(item.date).utc().format('DD/MM/YYYY') ===
          dayjs(date).utc().format('DD/MM/YYYY')
      );
      if (
        dailyTemplate &&
        dailyTemplate?.period.length > 0 &&
        infoDatePlanning
      ) {
        const dailyWork = infoDatePlanning.leaves.find(
          (leave) => leave.type?.name === 'WORK'
        );
        if (dailyWork) {
          modals.openConfirmModal({
            id: 'leave-request-form-dailywork-confirm',
            title: (
              <Title size={'h3'} component="p">
                {t('w.confirmPresence')}
              </Title>
            ),
            children: (
              <Text c={'dimmed'}>{t('w.confirmOrDeletePresence')}</Text>
            ),
            labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
            size: 'lg',
            onConfirm: () =>
              onCanvasClickConfirm(
                date,
                infoDatePlanning?.id,
                infoDatePlanning?.user?.division?.id
              ),
          });
          close();
        } else {
          const payload: DailyWorkRequest = {
            id: infoDatePlanning.id,
            date: dayjs(date).utc().format('DD/MM/YYYY'),
            presence: templateToString(dailyTemplate.period),
            overtime: 0,
            comment: '',
            status: 'WAITING',
            divisionConcerned: infoDatePlanning.divisionConcerned
              ? infoDatePlanning.divisionConcerned
              : infoDatePlanning.user.division.id,
          };
          updateUserPresence(payload);
        }
      }
    }
  }

  function onCanvasClickConfirm(
    presenceTime: Date,
    presenceId: string,
    divisionId: string
  ) {
    if (dailyTemplate && dailyTemplate?.period?.length > 0) {
      if (dailyTemplate.period.length > 0) {
        const data = {
          id: presenceId,
          date: dateString(presenceTime),
          presence: templateToString(dailyTemplate.period),
          status: 'WAITING',
          divisionConcerned: divisionId,
        };
        updateUserPresence(data);
      } else {
        showNotification({
          id: 'update-user-dailywork',
          message: t('w.emptyPeriodTemplate'),
          icon: <IconAlertCircle />,
          color: 'orange',
        });
      }
    }
  }

  function createDailyWorksBasedOnWeeklyTemplate() {
    const startOfWeekDay = dayjs(date).utc().startOf('week');
    const endOfWeekDay = dayjs(date).utc().endOf('week');

    const weekByDay: { label: string; date: string }[] = [];

    for (let i = 0; i < 7; i++) {
      weekByDay.push({
        label: startOfWeekDay
          .locale('en')
          .day(i === 6 ? i - 6 + 7 : i + 1)
          .format('dddd')
          .toUpperCase(),
        date: startOfWeekDay
          .day(i === 6 ? i - 6 + 7 : i + 1)
          .format('DD/MM/YYYY'),
      });
    }

    const userDailiesWorkFiltered = userDailyWork?.dailyWorks.filter(
      (item) =>
        item.date >= dayjs(date).utc().startOf('week').date() &&
        item.date < endOfWeekDay.utc().date()
    );
    const _weeklyPeriods: {
      date: string | undefined;
      day: string;
      period: PeriodTemplate[];
    }[] = [];
    const userDailiesWorkWithoutConflicts: any[] = [];

    if (weeklyTemplate && weeklyTemplate.weeklyPeriods.length > 0) {
      weeklyTemplate.weeklyPeriods.forEach((item) => {
        const daysOfWeekFinded = weekByDay.find(
          (dayOfWeek) => dayOfWeek.label === item.day
        );
        _weeklyPeriods.push({
          ...item,
          date: daysOfWeekFinded?.date,
        });
      });

      const infoDatePlanning = userDailyWork?.dailyWorks.find(
        (item) =>
          dayjs(item.date).utc().format('DD/MM/YYYY') ===
          dayjs(date).utc().format('DD/MM/YYYY')
      );
      if (infoDatePlanning) {
        const dailyWork = infoDatePlanning.leaves.find(
          (leave) => leave.type?.name === 'WORK'
        );
        if (dailyWork) {
          const userDailiesWorkInConflicts: any[] = [];
          if (userDailiesWorkFiltered) {
            userDailiesWorkFiltered.forEach((item) => {
              const weeklyPeriodsInConflict = _weeklyPeriods.find(
                (weeklyPeriod) =>
                  weeklyPeriod.date === dateString(item.date) &&
                  item.leaves.length
              );

              const weeklyPeriodsWithoutConflict = _weeklyPeriods.find(
                (weeklyPeriod) =>
                  weeklyPeriod.date === dateString(item.date) &&
                  item.leaves.length === 0
              );

              userDailiesWorkInConflicts.push({
                ...weeklyPeriodsInConflict,
                id: item.id,
                leaves: item.leaves,
                templateChecked: false,
                userId: userId,
                divisionId: userProfile?.divisionId,
                divisionConcerned: item.divisionConcerned,
              });

              userDailiesWorkWithoutConflicts.push({
                ...weeklyPeriodsWithoutConflict,
                id: item.id,
              });
            });

            const userDailiesWorkInConflictsFiltered =
              userDailiesWorkInConflicts.filter((conflict) => conflict.period);

            if (userDailiesWorkInConflictsFiltered.length > 1) {
              modals.open({
                modalId: 'leave-request-form-dailywork-conflict',
                title: (
                  <Title size={'h3'} component="p">
                    {'Présences en conflit avec les périodes du modèle'}
                  </Title>
                ),
                size: '90%',
                children: (
                  <DailyWorkConflictedModal
                    onClose={close}
                    userId={userId}
                    userDailyworksConflicted={
                      userDailiesWorkInConflictsFiltered
                    }
                    refresh={refresh}
                    userWeeklyTemplate={weeklyTemplate}
                    updateUserPresence={updateUserPresence}
                  />
                ),
              });
              close();
            }

            showNotification({
              id: 'update-user-dailywork',
              message: t('w.updateInProgressPleaseWait'),
              icon: <IconCheck />,
              color: 'green',
            });
            const userDailiesWorkWithoutConflictsFiltered =
              userDailiesWorkWithoutConflicts.filter(
                (withoutConflict) => withoutConflict.period
              );
            let index = 0;
            userDailiesWorkWithoutConflictsFiltered.forEach((item) => {
              index = ++index;
              const data = {
                id: item.id,
                date: item.date,
                presence: templateToString(item),
                divisionConcerned: item.divisionId,
                status: 'WAITING',
              };
              updateUserPresence(data);
            });
            if (index === _weeklyPeriods.length) {
              showNotification({
                id: 'update-user-dailywork',
                message: t('w.success'),
                icon: <IconCheck />,
                color: 'green',
              });
            }
          }
        } else {
          let index = 0;
          _weeklyPeriods.forEach((item) => {
            index = ++index;
            if (userProfile) {
              const data: DailyWorkRequest = {
                id: '',
                date: item.date,
                presence: item.period.length
                  ? templateToString(item.period)
                  : '',
                overtime: 0,
                comment: '',
                status: 'WAITING',
                divisionConcerned: userProfile.divisionId,
              };
              updateUserPresence(data);
            }
          });
          if (index === _weeklyPeriods.length) {
            showNotification({
              id: 'update-user-dailywork',
              message: t('w.success'),
              icon: <IconCheck />,
              color: 'green',
            });
          }
        }
      }
      close();
    }
  }

  const { mutate: updateUserPresence } = useMutation({
    mutationFn: (payload: DailyWorkRequest) =>
      DailyWorkService.updateUserDailyWork(userId, payload),
    onSuccess: () => {
      refresh();
      close();
      if (dailyTemplate) {
        showNotification({
          id: 'update-user-dailywork',
          message: t('w.success'),
          icon: <IconCheck />,
          color: 'green',
        });
      }
    },
    onError: (error) => console.log(handleErrorMessage(error, t)),
  });

  useEffect(() => {
    if (userProfile && !dailyTemplate && !weeklyTemplate) {
      modals.open({
        modalId: 'leave-request-form',
        title: (
          <Title size={'h3'} component="p">
            {t('w.requestForLeaveOrAbsence', userProfile.fullname)}
          </Title>
        ),
        size: '90%',
        children: (
          <LeavesRequestFormModal
            onClose={close}
            user={userProfile}
            defaultDate={date}
            refresh={refresh}
          />
        ),
      });
    }
  }, [userProfile, dailyTemplate, weeklyTemplate]);

  function close() {
    onClose();
    modals.close('leave-request-form');
  }

  return null;
}
