import { Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ProfileService from '../../../services/ProfileService';
import { type User, UserEmergencyContactType } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import {
  ALL,
  ConfigureEmployees,
  UserEmergencyContactEdit,
} from '../../../variables/BuiltInPermissions';

type Props = {
  userEmergencyContact: UserEmergencyContactType[] | undefined;
  refetchUserEmergencyContact: any;
  user: User; // oauth user
  userProfile?: User | null; // user added/checked/updated
};

export default function EmergencyContactTable({
  userEmergencyContact,
  refetchUserEmergencyContact,
  user,
  userProfile,
}: Props) {
  const { t, lang } = useI18n();
  const { access_token } = useAuth();

  const columns: DataTableColumn<UserEmergencyContactType>[] = useMemo(
    () => [
      {
        accessor: 'firstname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ firstname, lastname }) => (
          <Text fz={'sm'}>{firstname + ' ' + lastname}</Text>
        ),
      },
      {
        accessor: 'email',
        title: 'Email',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'phone',
        title: t('w.phone'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
        render: ({ comment }) => (comment ? <Text>{comment}</Text> : ''),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: ({ id, userId }) => {
          return (
            <Group gap={4} justify="end" wrap={'nowrap'}>
              {hasPermission(
                [
                  { permission: UserEmergencyContactEdit, scope: ALL },
                  { permission: ConfigureEmployees, scope: ALL },
                ],
                user
              ) && (
                <CustomTooltip label={t('w.personalDocuments')}>
                  <ActionIcon
                    size="sm"
                    color="red"
                    variant={'subtle'}
                    onClick={() =>
                      modals.openConfirmModal({
                        title: (
                          <Title size={'h3'} component="p">
                            {t('w.confirmDeleteEmergencyContact')}
                          </Title>
                        ),
                        children: (
                          <Text fz="xs" fw={'bold'} c={'dimmed'} mb={'xl'}>
                            {t('w.confirmDeleteEmergencyContactMessage')}
                          </Text>
                        ),
                        labels: {
                          confirm: t('w.delete'),
                          cancel: t('w.cancel'),
                        },
                        onConfirm: () =>
                          deleteEmergencyContact({
                            emergencyContactId: id,
                            userId,
                          }),
                      })
                    }
                  >
                    <IconTrash size={16} />
                  </ActionIcon>
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
    ],
    [userEmergencyContact]
  );

  const { mutate: deleteEmergencyContact } = useMutation({
    mutationFn: (variables: {
      emergencyContactId: string | undefined | null;
      userId: string | undefined;
    }) =>
      ProfileService.deleteUserEmergencyContact(
        variables.userId,
        variables.emergencyContactId,
        access_token
      ),
    onSuccess: () => {
      showNotification({
        id: 'delete-emergency-contact-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchUserEmergencyContact();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-personal-info-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', userProfile?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      rows={userEmergencyContact}
      columns={columns}
      lang={lang}
      withTableBorder={false}
    />
  );
}
