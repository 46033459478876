import React, { ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useAuth } from '../contexts/AuthProvider';
import type { PermissionScoped } from '../types/types';
import { hasPermission, isAccountant } from '../utils/authorization';
import { FRONT_URL } from '../variables/GlobalVariables';
import AppShell from './layouts/AppShell/AppShell';

type Props = {
  children: ReactNode;
  permissions?: PermissionScoped[];
};

export default function RequireAuth({ children, permissions = [] }: Props) {
  const { user, appInitialized } = useAuth();
  let location = useLocation();
  const { companyId } = useParams();
  const redirect = window.location.pathname;
  console.log(location);

  function canAccess(): boolean {
    let isAuthorized = false;
    if (companyId) {
      isAuthorized = isAccountant(user, companyId);
    }

    // still not authorized, test if user have permission
    if (!isAuthorized) {
      if (permissions.length > 0) {
        isAuthorized = hasPermission(permissions, user);
      } else {
        isAuthorized = true;
      }
    }

    return isAuthorized;
  }

  if (!appInitialized)
    return (
      <Navigate
        to={`/?redirectUri=${FRONT_URL}${redirect}`}
        state={{ from: location }}
        replace
      />
    );

  if (canAccess()) return <AppShell>{children}</AppShell>;

  return <Navigate to="/error404" state={{ from: location }} replace />;
}
