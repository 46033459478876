import { useQuery } from '@tanstack/react-query';

import CkModuleManagerServiceMS from '../services/CkModuleManagerServiceMS';
import type { Module } from '../types/types';
import type { ModuleNameType } from '../variables/ModulesV2';

export default function useCompanyModules(companyId: string) {
  const { data: modules } = useQuery({
    enabled: !!companyId,
    queryKey: ['CkModuleManagerServiceMS.list', companyId],
    queryFn: () => CkModuleManagerServiceMS.list(companyId, 'companyFeature'),
  });

  function getModule(moduleName: ModuleNameType): Module | undefined {
    if (!modules?.length) return undefined;

    return modules.find((module: Module) => module.name === moduleName);
  }

  return { modules, getModule };
}
