import {
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  rem,
  Space,
  Tabs,
  Text,
  Title,
  useMantineColorScheme,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useDisclosure } from '@mantine/hooks';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { Fragment, useMemo, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import ContractualStepper from '../../../components/ContractualStepper';
import CustomActionIconFile from '../../../components/CustomActionIconFile';
import CustomModal from '../../../components/CustomModal';
import { NotificationCard } from '../../../components/NotificationCard/NotificationCard';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useUserCompanyModules from '../../../hooks/useUserCompanyModules';
import ProfileService from '../../../services/ProfileService';
import { DailyWorkParams } from '../../../types/types';
import { handleOpenSensitiveDocument } from '../../../utils/attachmentFile';
import { hasPermission } from '../../../utils/authorization';
import { roleName } from '../../../utils/roleName';
import {
  ALL,
  AuthorizedNotificationReceive,
  AuthorizedReadAllUsersInfo,
  AuthorizedReadDailyWork,
  AuthorizedReadEmployeesPayslips,
  AuthorizedUpsertDailyWork,
  ConfigureEmployees,
  UserContractualInfoEdit,
  UserContractualInfoShow,
  UserEmergencyContactEdit,
  UserEmergencyContactShow,
  UserPersonalInfoEdit,
  UserPersonalInfoShow,
  ValidateExpenseReports,
  ValidateLeavesAbsences,
} from '../../../variables/BuiltInPermissions';
import {
  MS_WHITE_LABEL_ID,
  NO_WORK_UNIT,
} from '../../../variables/GlobalVariables';
import { DailyWork, ExpenseReport } from '../../../variables/ModulesV2';
import UserActivities from '../../activities/my-activities/components/UserActivities';
import OnBoardingStatusModal from '../../company/employees/components/OnBoardingStatusModal';
import ExpenseReportsUser from '../../expense-reports/components/ExpenseReportsUser';
import Payslips from '../../payslips/Payslips';
import Notifications from '../../user-account/component/notifications/Notifications';
import CustomAvatarContainer from '../components/CustomAvatarContainer';
import EditUserProfileButtonContainer from '../components/EditUserProfileButtonContainer';
import EmergencyContactTable from '../components/EmergencyContactTable';
import InfosContainer from '../components/InfosContainer';
import UserProfileEditModal from '../components/UserProfileEditModal';
import UserProfileLeaves from '../components/UserProfileLeaves';
import UserProfileSkeleton from '../components/UserProfileSkeleton';
import UserWeekRepartitionForm from '../components/UserWeekRepartitionForm';
import s from './UserProfileSettings.module.sass';

dayjs.extend(utc);

interface ContractFormValues {
  userId?: string | null;
  professionalQualifications?: string;
  contractType?: string;
  otherContractType?: string | null;
  payslipName?: string;
  regime?: string;
  regimeLevel?: string;
  otherRegime?: string | null;
  weeklyHours?: number | string;
  monthlyHours?: number | string;
  yearlyHours?: number | string;
  grossPay?: number | string;
  entryDate?: Date | string | null;
  exitDate?: Date | string | null;
  employmentAuthorizationDate?: Date | string | null;
  layoffAuthorizationDate?: Date | string | null;
  comment?: string | null;
  contractFile?: string | null;
  file?: FileWithPath[];
  employment?: string;
}

export default function UserProfileSettings() {
  const { user, access_token } = useAuth();
  const { t } = useI18n();
  let location = useLocation();
  const { colorScheme } = useMantineColorScheme();
  const [activeTab, setActiveTab] = useState<string | null>('information');
  const [opened, handlers] = useDisclosure(false);
  const [file, setFile] = useState({
    opened: false,
    path: '',
    type: '',
    loading: false,
  });
  const { userId } = useParams();
  const { getModule, modules } = useUserCompanyModules(userId as string);
  const navigate = useNavigate();

  const { data: userProfile, isLoading } = useQuery({
    queryKey: ['ProfileService.getUserProfile', userId],
    queryFn: () => ProfileService.getUserProfile(access_token, userId),
    onError: () => navigate('/'),
  });
  const { data: userContractualInfo, refetch: refetchUserContractualInfo } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            { permission: ConfigureEmployees, scope: ALL },
            { permission: UserContractualInfoShow, scope: ALL },
          ],
          user
        ),
      queryKey: ['ProfileService.getUserContractualInfo', userId],
      queryFn: () => ProfileService.getUserContractualInfo(userId),
      onError: () => navigate('/'),
    });
  const { data: userPersonalFamily, refetch: refetchUserFamilySituation } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            { permission: UserPersonalInfoShow, scope: ALL },
            { permission: ConfigureEmployees, scope: ALL },
          ],
          user
        ),
      queryKey: ['ProfileService.getUserPersonalFamilySituation', userId],
      queryFn: () => ProfileService.getUserPersonalFamilySituation(userId),
      onError: () => navigate('/'),
    });
  const { data: userPersonalInfo, refetch: refetchUserPersonalInfo } = useQuery(
    {
      enabled:
        userId === user.id ||
        hasPermission(
          [
            { permission: UserPersonalInfoShow, scope: ALL },
            { permission: ConfigureEmployees, scope: ALL },
          ],
          user
        ),
      queryKey: ['ProfileService.getUserPersonalInfo', userId],
      queryFn: () => ProfileService.getUserPersonalInfo(userId),
      onError: () => navigate('/'),
    }
  );
  const { data: userSensitiveInfo, refetch: refetchUserSensitiveInfo } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            { permission: UserPersonalInfoShow, scope: ALL },
            { permission: ConfigureEmployees, scope: ALL },
          ],
          user
        ),
      queryKey: ['ProfileService.getUserSensitiveInfo', userId],
      queryFn: () => ProfileService.getUserSensitiveInfo(userId),
      onError: () => navigate('/'),
    });
  const { data: userEmergencyContact, refetch: refetchUserEmergencyContact } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            { permission: UserEmergencyContactShow, scope: ALL },
            { permission: ConfigureEmployees, scope: ALL },
          ],
          user
        ),
      queryKey: ['ProfileService.getUserEmergencyContact', userId],
      queryFn: () => ProfileService.getUserEmergencyContact(userId),
      onError: () => navigate('/'),
    });
  const { data: onBoardingInfos, refetch: refetchOBInfos } = useQuery({
    queryKey: ['ProfileService.employeeOnBoardingInfos', userId],
    queryFn: () => ProfileService.employeeOnBoardingInfos(userId),
    onError: () => navigate('/'),
  });

  const tabs = useMemo(() => {
    const isModuleExpenseReportActiveForCompany =
      getModule(ExpenseReport)?.active;
    const isModuleDailyWorkActiveForCompany = getModule(DailyWork)?.active;
    const isModuleDailyWorkActiveForAll = (
      getModule(DailyWork)?.configuration?.params as DailyWorkParams
    )?.activeForAll;
    const isActiveForUser = Boolean(
      userProfile?.paidFeatures?.find((item) => item.active)
    );

    return [
      {
        value: 'information',
        label: 'Informations',
        disabled: false,
        isDisplayed: true,
      },
      {
        value: 'family-information',
        label: t('w.familySituation'),
        disabled: false,
        isDisplayed:
          userId === user.id ||
          hasPermission(
            [
              { permission: UserPersonalInfoShow, scope: ALL },
              { permission: ConfigureEmployees, scope: ALL },
            ],
            user
          ),
      },
      {
        value: 'contract',
        label: t('w.contractual'),
        disabled: false,
        isDisplayed:
          userId === user.id ||
          hasPermission(
            [
              { permission: ConfigureEmployees, scope: ALL },
              { permission: UserContractualInfoShow, scope: ALL },
            ],
            user
          ),
      },
      {
        value: 'company',
        label: t('w.company'),
        disabled: false,
        isDisplayed: true,
      },
      {
        value: 'bank-informations',
        label: t('w.bankInformation'),
        disabled: false,
        isDisplayed:
          userId === user.id ||
          hasPermission(
            [
              { permission: UserPersonalInfoShow, scope: ALL },
              { permission: ConfigureEmployees, scope: ALL },
            ],
            user
          ),
      },
      {
        value: 'emergency-contact',
        label: t('w.personToContact'),
        disabled: false,
        isDisplayed:
          userId === user.id ||
          hasPermission(
            [
              { permission: UserEmergencyContactShow, scope: ALL },
              { permission: ConfigureEmployees, scope: ALL },
            ],
            user
          ),
      },
      {
        value: 'week-repartition',
        label: t('w.weeklySchedule'),
        disabled: false,
        isDisplayed: true,
      },
      {
        value: 'leaves',
        label: t('w.leavesAndAbsences'),
        disabled: false,
        isDisplayed: true,
      },
      {
        value: 'activities',
        label: t('w.activities'),
        disabled: false,
        isDisplayed:
          isModuleDailyWorkActiveForCompany &&
          hasPermission(
            [
              { permission: ValidateLeavesAbsences, scope: ALL },
              { permission: AuthorizedUpsertDailyWork, scope: ALL },
              { permission: AuthorizedReadDailyWork, scope: ALL },
            ],
            user
          ) &&
          (isModuleDailyWorkActiveForAll || isActiveForUser),
      },
      {
        value: 'expense-reports',
        label: t('w.expenseReports'),
        disabled: false,
        isDisplayed:
          isModuleExpenseReportActiveForCompany &&
          hasPermission(
            [{ permission: ValidateExpenseReports, scope: ALL }],
            user
          ),
      },
      {
        value: 'payslips',
        label: t('w.payslips'),
        disabled: false,
        isDisplayed:
          (hasPermission(
            [
              { permission: AuthorizedReadEmployeesPayslips, scope: ALL },
              { permission: AuthorizedReadAllUsersInfo, scope: ALL },
            ],
            user
          ) ||
            user.id === userProfile?.id) &&
          userProfile?.division?.name !== NO_WORK_UNIT,
      },

      {
        value: 'notifications-users',
        label: t('w.profileNotifications'),
        disabled: false,
        isDisplayed:
          userId === user.id ||
          hasPermission({ permission: ConfigureEmployees, scope: ALL }, user),
      },
      {
        value: 'notifications-company',
        label: t('w.companyNotifications'),
        disabled: false,
        isDisplayed:
          userId === user.id &&
          hasPermission(
            [{ permission: AuthorizedNotificationReceive, scope: ALL }],
            user
          ),
      },
      {
        value: 'gain',
        label: t('w.salary'),
        disabled: true,
        isDisplayed: false,
      },
      {
        value: 'cotisations',
        label: t('w.contributions'),
        disabled: true,
        isDisplayed: false,
      },
      {
        value: 'onboarding',
        label: t('w.onBoardingInformations'),
        disabled: false,
        isDisplayed:
          onBoardingInfos &&
          !Boolean(userProfile?.onBoardingId) &&
          (hasPermission(
            [{ permission: AuthorizedReadAllUsersInfo, scope: ALL }],
            user
          ) ||
            user.id === userProfile?.id),
      },
    ];
  }, [userProfile, user, userId, modules]);

  const userContractInfos: ContractFormValues = useMemo(() => {
    return {
      userId: userContractualInfo?.userId,
      professionalQualifications:
        userContractualInfo?.professionalQualifications
          ? userContractualInfo.professionalQualifications
          : '-',
      contractType: userContractualInfo?.contractType
        ? userContractualInfo?.contractType === 'OTHER'
          ? userContractualInfo?.otherContractType
          : userContractualInfo?.contractType
        : '-',
      otherContractType: userContractualInfo?.otherContractType,
      payslipName: userContractualInfo?.payslipName
        ? userContractualInfo?.payslipName
        : '-',
      regime: userContractualInfo?.regime
        ? userContractualInfo?.regime === 'OTHER'
          ? userContractualInfo?.otherRegime
          : userContractualInfo?.regime
        : '-',
      regimeLevel: userContractualInfo?.regimeLevel
        ? userContractualInfo?.regimeLevel
        : '-',
      otherRegime: userContractualInfo?.otherRegime
        ? userContractualInfo?.otherRegime
        : '-',
      weeklyHours: userContractualInfo?.weeklyHours
        ? userContractualInfo?.weeklyHours
        : '',
      monthlyHours: userContractualInfo?.monthlyHours
        ? userContractualInfo?.monthlyHours
        : '',
      yearlyHours: userContractualInfo?.yearlyHours
        ? userContractualInfo?.yearlyHours
        : '',
      grossPay: userContractualInfo?.grossPay
        ? userContractualInfo?.grossPay
        : '-',
      entryDate: userContractualInfo?.entryDate
        ? dayjs(userContractualInfo.entryDate).format('DD/MM/YYYY')
        : '-',
      exitDate: userContractualInfo?.exitDate
        ? dayjs(userContractualInfo.exitDate).format('DD/MM/YYYY')
        : '-',
      employmentAuthorizationDate:
        userContractualInfo?.employmentAuthorizationDate
          ? dayjs(userContractualInfo.employmentAuthorizationDate).format(
              'DD/MM/YYYY'
            )
          : '-',
      layoffAuthorizationDate: userContractualInfo?.layoffAuthorizationDate
        ? dayjs(userContractualInfo.layoffAuthorizationDate).format(
            'DD/MM/YYYY'
          )
        : '-',
      comment: userContractualInfo?.comment
        ? userContractualInfo?.comment
        : '-',
      contractFile: userContractualInfo?.contractFile,
      file: [],
      employment: userProfile?.employment,
    };
  }, [userContractualInfo, userProfile]);

  const userProfileInfos = useMemo(
    () => [
      {
        firstLabel: t('w.emailAddress'),
        secondLabel: userProfile?.mail,
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.status'),
        secondLabel: userProfile?.valid ? t('w.active') : t('w.inactive'),
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.nationality'),
        secondLabel:
          userPersonalInfo?.nationality && userPersonalInfo?.nationality !== ''
            ? userPersonalInfo?.nationality
            : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.phone'),
        secondLabel:
          userPersonalInfo?.phoneNumber && userPersonalInfo?.phoneNumber !== ''
            ? userPersonalInfo.phoneNumber
            : '-',
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.civilStatus'),
        secondLabel: userPersonalInfo?.gender
          ? t('gender.' + userPersonalInfo.gender)
          : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },

      {
        firstLabel: t('w.dateOfBirth'),
        secondLabel: userPersonalInfo?.birthday
          ? dayjs(userPersonalInfo.birthday).format('DD/MM/YYYY')
          : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.placeOfBirth'),
        secondLabel: userPersonalInfo?.birthPlace
          ? userPersonalInfo.birthPlace
          : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.departmentOfBirth'),
        secondLabel: userPersonalInfo?.birthDepartment
          ? userPersonalInfo.birthDepartment
          : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: MS_WHITE_LABEL_ID === 'AFRICAPAIERH',
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.address'),
        secondLabel:
          userPersonalInfo?.address &&
          userPersonalInfo.address.address1 !== null &&
          userPersonalInfo.address.address1 !== ''
            ? userPersonalInfo.address.address1 +
              ', ' +
              userPersonalInfo.address.address2 +
              ' ' +
              userPersonalInfo.address.zipCode +
              ' ' +
              userPersonalInfo.address.city
            : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: false,
      },
    ],
    [userProfile, userPersonalInfo]
  );

  const familySituation = useMemo(
    () => [
      {
        firstLabel: t('w.familySituation'),
        secondLabel: userPersonalFamily?.familyState
          ? t('familyState.' + userPersonalFamily?.familyState)
          : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.numberOfDependants'),
        secondLabel: userPersonalFamily?.numberDependents
          ? userPersonalFamily?.numberDependents.toString()
          : '-',
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.numberOfDependentChildren'),
        secondLabel: userPersonalFamily?.numberDependentsChildren
          ? userPersonalFamily?.numberDependentsChildren.toString()
          : '-',
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
    ],
    [userPersonalFamily]
  );

  const companyInfos = useMemo(
    () => [
      {
        firstLabel: t('w.validatorN1'),
        secondLabel: userProfile?.nameOfN1
          ? userProfile.nameOfN1
          : t('w.noValue'),
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.validatorN2'),
        secondLabel: userProfile?.nameOfN2
          ? userProfile.nameOfN2
          : t('w.noValue'),
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: !userProfile?.company?.useN2,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.role'),
        secondLabel: userProfile?.role
          ? roleName(t, userProfile.role.name)
          : '',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.unit'),
        secondLabel:
          userProfile?.division?.name === NO_WORK_UNIT
            ? t(NO_WORK_UNIT)
            : t(`${userProfile?.division?.name}`),
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
    ],
    [userProfile]
  );

  const userPersonalSensitive = useMemo(
    () => [
      {
        firstLabel: t('w.nationalIdentityCardNumber'),
        secondLabel: userSensitiveInfo?.identityCardNumber
          ? userSensitiveInfo?.identityCardNumber
          : '-',
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.identityDocument'),
        secondLabel: (
          <CustomActionIconFile
            fileName={'identity-card'}
            onClick={() =>
              handleOpenSensitiveDocument(
                access_token,
                t,
                'identityCard',
                userId ? userId : ''
              )
            }
          />
        ),
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: true,
        divider: true,
      },
      {
        firstLabel: t('w.socialSecurityNumber'),
        secondLabel: userSensitiveInfo?.ssNumber
          ? userSensitiveInfo?.ssNumber
          : '-',
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.vitaleCard'),
        secondLabel: (
          <CustomActionIconFile
            fileName={'vitaleCard'}
            onClick={() =>
              handleOpenSensitiveDocument(
                access_token,
                t,
                'securitySocial',
                userId ? userId : ''
              )
            }
          />
        ),
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: true,
        divider: true,
      },
      {
        firstLabel: t('w.residenceNumber'),
        secondLabel: userSensitiveInfo?.residencePermitNumber
          ? userSensitiveInfo?.residencePermitNumber
          : '-',
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.residencePermitFile'),
        secondLabel: (
          <CustomActionIconFile
            fileName={'residencePermitFile'}
            onClick={() =>
              handleOpenSensitiveDocument(
                access_token,
                t,
                'residencePermit',
                userId ? userId : ''
              )
            }
          />
        ),
        firstColor: 'dimmed',
        secondColor: 'hifivework.6',
        hidden: false,
        detail: true,
        divider: true,
      },
      {
        firstLabel: t('w.handicappedWorker'),
        secondLabel: userSensitiveInfo?.handicappedWorker
          ? t('w.' + userSensitiveInfo.handicappedWorker)
          : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: false,
      },
    ],
    [userSensitiveInfo]
  );
  const userBankInformation = useMemo(
    () => [
      {
        firstLabel: t('w.iban'),
        secondLabel: userSensitiveInfo?.iban ? userSensitiveInfo.iban : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
      {
        firstLabel: t('w.bic'),
        secondLabel: userSensitiveInfo?.bic ? userSensitiveInfo.bic : '-',
        firstColor: 'dimmed',
        secondColor: undefined,
        hidden: false,
        detail: false,
        divider: true,
      },
    ],
    [userSensitiveInfo]
  );

  const actionButtons = useMemo(() => {
    if (!userProfile) return undefined;

    if (!hasPermission({ permission: ConfigureEmployees, scope: ALL }, user))
      return undefined;

    return [
      <Button
        key={'return-to-company-employees'}
        color={'gray'}
        leftSection={<IconArrowNarrowLeft />}
        variant={'subtle'}
        onClick={() => navigate(`/company/${userProfile.company.id}/employees`)}
      >
        {t('w.backToList')}
      </Button>,
    ];
  }, [userProfile, user]);

  function renderFileViewer() {
    switch (file.type) {
      case 'pdf':
      case 'PDF':
        return (
          <div className={s.root}>
            <iframe
              id="iFrame"
              src={file.path}
              title={'residencePermitFile'}
              className={s.iframe}
            />
          </div>
        );
      default:
        return <Image src={file.path} width={400} height={500} />;
    }
  }

  if (!userId)
    return <Navigate to="/error404" state={{ from: location }} replace />;
  return (
    <>
      {isLoading ? (
        <Container fluid mt={'md'}>
          <UserProfileSkeleton />
        </Container>
      ) : (
        <ScreenWrapper
          title={userProfile?.fullname}
          actionButtons={actionButtons}
        >
          <LoadingOverlay visible={file.loading} />
          <Space h={'xs'} />
          <Tabs value={activeTab} onChange={setActiveTab}>
            <Tabs.List>
              {tabs
                .filter((tab) => tab.isDisplayed)
                .map((tab) => (
                  <Tabs.Tab
                    key={tab.value}
                    bg={
                      activeTab === tab.value && colorScheme === 'light'
                        ? 'white'
                        : 'transparent'
                    }
                    value={tab.value}
                    disabled={tab.disabled}
                  >
                    {tab.label}
                  </Tabs.Tab>
                ))}
            </Tabs.List>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="information"
              pt="xs"
            >
              <Space h={'xs'} />
              <Grid align="flex-start" gutter="xl">
                <CustomAvatarContainer userId={userId} />
                <Grid.Col span={5} style={{ minHeight: rem(60) }}>
                  {userProfileInfos
                    .filter((item) => !item.hidden)
                    .map((item, index) => (
                      <InfosContainer
                        key={`${item.secondLabel}-${index}`}
                        item={item}
                      />
                    ))}
                </Grid.Col>
                {userProfile && (
                  <EditUserProfileButtonContainer
                    user={user}
                    userProfile={userProfile}
                    onClick={() => handlers.open()}
                  />
                )}
              </Grid>
              <Space h={'xl'} />

              <Grid align="flex-start" gutter="xl" ml={'xl'}>
                {hasPermission(
                  [
                    { permission: UserPersonalInfoShow, scope: ALL },
                    { permission: ConfigureEmployees, scope: ALL },
                  ],
                  user
                ) && (
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <Flex direction={'column'} gap={'xs'}>
                      <Title order={2}>{t('w.otherInformation')}</Title>
                      <Paper
                        shadow="lg"
                        p="md"
                        withBorder
                        radius={'md'}
                        styles={{
                          root: {
                            borderWidth: 2,
                          },
                        }}
                      >
                        {userPersonalSensitive
                          .filter((item) => !item.hidden)
                          .map((item, index) => (
                            <InfosContainer
                              key={`${item.secondLabel}-${index}`}
                              item={item}
                            />
                          ))}
                      </Paper>
                    </Flex>
                  </Grid.Col>
                )}
                {onBoardingInfos && Boolean(userProfile?.onBoardingId) && (
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <Flex direction={'column'} gap={'xs'}>
                      <Title order={2}>{t('w.onBoardingInformations')}</Title>
                      <Paper
                        shadow="lg"
                        p="md"
                        withBorder
                        radius={'md'}
                        styles={{
                          root: {
                            borderWidth: 2,
                          },
                        }}
                      >
                        <OnBoardingStatusModal
                          onBoarding={onBoardingInfos}
                          user={user}
                          refetchOBInfos={refetchOBInfos}
                        />
                      </Paper>
                    </Flex>
                  </Grid.Col>
                )}
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="bank-informations"
              pt="xs"
            >
              <Space h={'xl'} />
              <Grid gutter="xl">
                <Grid.Col ml={'xl'} span={5} style={{ minHeight: rem(120) }}>
                  {userBankInformation
                    .filter((item) => !item.hidden)
                    .map((item, index) => (
                      <InfosContainer
                        key={`${item.secondLabel}-${index}`}
                        item={item}
                      />
                    ))}
                </Grid.Col>
                {userProfile &&
                  hasPermission(
                    [
                      { permission: UserPersonalInfoEdit, scope: ALL },
                      { permission: ConfigureEmployees, scope: ALL },
                    ],
                    user
                  ) && (
                    <EditUserProfileButtonContainer
                      user={user}
                      userProfile={userProfile}
                      onClick={() => handlers.open()}
                    />
                  )}
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="emergency-contact"
              pt="xs"
            >
              <Space h={'xl'} />
              <Grid gutter="xl">
                <Grid.Col ml={'xl'} span={8} style={{ minHeight: rem(120) }}>
                  {userEmergencyContact && userEmergencyContact.length > 0 ? (
                    <Grid p={'md'} gutter="xl">
                      <EmergencyContactTable
                        userEmergencyContact={userEmergencyContact}
                        refetchUserEmergencyContact={
                          refetchUserEmergencyContact
                        }
                        user={user}
                        userProfile={userProfile}
                      />
                    </Grid>
                  ) : (
                    <Text>{t('w.noEmergencyContactYet')}</Text>
                  )}
                </Grid.Col>
                {userProfile &&
                  hasPermission(
                    [
                      { permission: UserEmergencyContactEdit, scope: ALL },
                      { permission: ConfigureEmployees, scope: ALL },
                    ],
                    user
                  ) && (
                    <EditUserProfileButtonContainer
                      user={user}
                      userProfile={userProfile}
                      onClick={() => handlers.open()}
                    />
                  )}
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="family-information"
              pt="xs"
            >
              <Space h={'xl'} />
              <Grid gutter="xl">
                <Grid.Col ml={'xl'} span={5} style={{ minHeight: rem(120) }}>
                  {familySituation
                    .filter((item) => !item.hidden)
                    .map((item, index) => (
                      <InfosContainer
                        key={`${item.secondLabel}-${index}`}
                        item={item}
                      />
                    ))}
                </Grid.Col>
                {userProfile &&
                  hasPermission(
                    [
                      { permission: UserPersonalInfoEdit, scope: ALL },
                      { permission: ConfigureEmployees, scope: ALL },
                    ],
                    user
                  ) && (
                    <EditUserProfileButtonContainer
                      user={user}
                      userProfile={userProfile}
                      onClick={() => handlers.open()}
                    />
                  )}
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="contract"
              pt="xs"
              pl={'xl'}
            >
              <Space h={'lg'} />
              {userProfile &&
                hasPermission(
                  [
                    { permission: UserContractualInfoEdit, scope: ALL },
                    { permission: ConfigureEmployees, scope: ALL },
                  ],
                  user
                ) && (
                  <Grid justify={'flex-end'}>
                    <EditUserProfileButtonContainer
                      user={user}
                      userProfile={userProfile}
                      onClick={handlers.open}
                    />
                  </Grid>
                )}
              <ContractualStepper
                form={userContractInfos}
                request={false}
                employee={userProfile}
              />
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="company"
              pt="xs"
              pl={'xl'}
            >
              <Space h={'xl'} />
              <Grid gutter="xl">
                <Grid.Col ml={'xl'} span={5} style={{ minHeight: rem(120) }}>
                  {companyInfos
                    .filter((item) => !item.hidden)
                    .map((item, index) => (
                      <InfosContainer
                        key={`${item.secondLabel}-${index}`}
                        item={item}
                      />
                    ))}
                  {userProfile?.identifiantsCompany &&
                    userProfile.identifiantsCompany.length > 0 && (
                      <>
                        {userProfile.identifiantsCompany.map((item, index) => (
                          <Fragment
                            key={`${item.codeCompany}-${item.matricule}-${index}`}
                          >
                            <Group justify={'space-between'}>
                              <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                                {`${t('w.companyCode')} / ${t(
                                  'w.serialNumber'
                                )}`}
                              </Text>
                              <Group>
                                <Text fz={'sm'} fw={'bold'}>
                                  {item.codeCompany === ''
                                    ? t('w.noValue')
                                    : item.codeCompany}
                                </Text>
                                <Text> / </Text>
                                <Text fz={'sm'} fw={'bold'}>
                                  {item.matricule === ''
                                    ? t('w.noValue')
                                    : item.matricule}
                                </Text>
                              </Group>
                            </Group>
                            <Divider my="sm" />
                          </Fragment>
                        ))}
                      </>
                    )}
                </Grid.Col>
                <Grid.Col
                  ml={'xl'}
                  span={5}
                  style={{ minHeight: rem(120) }}
                ></Grid.Col>
                {userProfile && (
                  <EditUserProfileButtonContainer
                    user={user}
                    userProfile={userProfile}
                    onClick={() => handlers.open()}
                  />
                )}
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="leaves"
              p={'md'}
            >
              {userProfile && (
                <UserProfileLeaves
                  userProfile={userProfile}
                  showTakeALeaveButton
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="activities"
              p={'md'}
            >
              {userProfile && activeTab === 'activities' && (
                <UserActivities userProfile={userProfile} />
              )}
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="expense-reports"
              p={'md'}
            >
              {userProfile && activeTab === 'expense-reports' && (
                <ExpenseReportsUser userProfile={userProfile} />
              )}
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="payslips"
              p={'md'}
            >
              {userProfile && activeTab === 'payslips' && <Payslips />}
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="week-repartition"
              py="md"
              pl={'md'}
            >
              {userProfile && (
                <UserWeekRepartitionForm userProfile={userProfile} />
              )}
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="notifications-users"
              pt="xs"
            >
              {userProfile && activeTab === 'notifications-users' && (
                <NotificationCard userId={userProfile.id} type={'user'} />
              )}
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="notifications-company"
              pt="xs"
            >
              <Notifications type={'company'} />
            </Tabs.Panel>
            <Tabs.Panel
              bg={colorScheme === 'light' ? 'white' : 'transparent'}
              value="onboarding"
              pt="xs"
            >
              <Grid justify={'center'}>
                <Grid.Col span={{ base: 12, md: 8 }}>
                  <Flex
                    direction={'column'}
                    gap={'xs'}
                    justify={'center'}
                    align={'center'}
                  >
                    <Title order={2}>{t('w.onBoardingInformations')}</Title>
                    <Paper
                      shadow="lg"
                      p="md"
                      withBorder
                      radius={'md'}
                      styles={{
                        root: {
                          borderWidth: 2,
                        },
                      }}
                    >
                      <OnBoardingStatusModal
                        onBoarding={onBoardingInfos}
                        user={user}
                        refetchOBInfos={refetchOBInfos}
                      />
                    </Paper>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
          <Space h={'xl'} />
        </ScreenWrapper>
      )}
      {userProfile && (
        <UserProfileEditModal
          opened={opened}
          onClose={handlers.close}
          userProfile={userProfile}
          userContractualInfo={userContractualInfo}
          userFamilySituation={userPersonalFamily}
          userPersonalInfo={userPersonalInfo}
          userSensitiveInfo={userSensitiveInfo}
          userEmergencyContact={userEmergencyContact}
          refetchUserEmergencyContact={refetchUserEmergencyContact}
          refetchUserPersonalInfo={refetchUserPersonalInfo}
          refetchUserSensitiveInfo={refetchUserSensitiveInfo}
          refetchUserFamilySituation={refetchUserFamilySituation}
          refetchUserContractualInfo={refetchUserContractualInfo}
          refetchOBInfos={refetchOBInfos}
        />
      )}
      <CustomModal
        size={'80%'}
        opened={file.opened}
        onClose={() =>
          setFile({ opened: false, type: '', path: '', loading: false })
        }
        title={t('w.residencePermitFile')}
      >
        <Center>{renderFileViewer()}</Center>
      </CustomModal>
    </>
  );
}
