import { usePrevious } from '@mantine/hooks';
import dayjs from 'dayjs';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { cloneDeep, findLastIndex } from 'lodash';
import { useEffect, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import type { PlanningObject } from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';

type Props = {
  companyId: string;
  divisionIds: string[];
  start: Date;
  end: Date;
};

type EventData = {
  id: string;
  userId: string;
  payload: PlanningObject;
};

export default function usePlanningSSE({
  divisionIds,
  companyId,
  start,
  end,
}: Props) {
  const { user, access_token } = useAuth();
  const url = !!divisionIds?.length
    ? `${MS_PLANNING_ENDPOINT}/v2/planning/real-time/sse/${
        user.id
      }/subscriptions/company/${companyId}/${dayjs(start)
        .subtract(1, 'week')
        .valueOf()}/${dayjs(end).add(1, 'week').valueOf()}?${divisionIds
        .map((item) => `divisionId=${encodeURIComponent(item)}`)
        .join('&')}`
    : undefined;

  let source: EventSource;
  const previousUrl = usePrevious(url);
  const [events, setEvents] = useState<EventData[]>([]);

  useEffect(() => {
    if (!!url) {
      if (previousUrl !== url) {
        closeSSE();
      }

      setEvents([]);
      subscribe(url);
    }

    return () => {
      closeSSE();
    };
  }, [url]);

  function subscribe(_url: string) {
    try {
      source = new EventSourcePolyfill(_url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      source.addEventListener(
        'message',
        function (e) {
          try {
            const newEventData: EventData = JSON.parse(e.data);
            if (!!newEventData && !!newEventData.payload) {
              setEvents((prevState) => {
                let result = cloneDeep(prevState);
                if (newEventData) {
                  const divisionIndexInData = result.findIndex(
                    (item) =>
                      item.payload?.division?.id ===
                      newEventData.payload?.division?.id
                  );
                  if (divisionIndexInData === -1) {
                    result.push(newEventData);
                  } else {
                    const users = result[
                      divisionIndexInData
                    ].payload.users.concat(newEventData.payload.users);
                    result[divisionIndexInData].payload.users = users.filter(
                      (value, index, self) => {
                        return (
                          findLastIndex(
                            self,
                            (v) => v.user.id === value.user.id
                          ) === index
                        );
                      }
                    );

                    const blockingPeriods = result[
                      divisionIndexInData
                    ].payload.blockingPeriods.concat(
                      newEventData.payload.blockingPeriods
                    );
                    result[divisionIndexInData].payload.blockingPeriods =
                      blockingPeriods.filter((value, index, self) => {
                        return (
                          self.findIndex(
                            (v) =>
                              v.period.start === value.period.start &&
                              v.period.end === value.period.end
                          ) === index
                        );
                      });
                  }
                }
                result = result.filter((item) =>
                  divisionIds.includes(item.payload.division.id)
                );
                return result;
              });
            }
          } catch (_e) {}
        },
        false
      );

      source.addEventListener('error', closeSSE, false);
    } catch (_e) {}
  }

  function closeSSE() {
    if (source) {
      source.close();
    }
  }

  function refetch() {
    if (source) {
      source.close();
    }

    if (!!url) {
      subscribe(url);
    }
  }

  return { events, refetch, loading: events.length !== divisionIds.length };
}
