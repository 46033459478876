import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import { MicrosoftIcon } from '../../../../components/icons/MicrosoftIcon';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { ProviderProps } from '../../Login';
import useMicrosoftLogin from './hooks/useMicrosoftLogin';

export const loginRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
  prompt: 'select_account',
};

export default function MicrosoftLogin({
  setOpenProfileChoice,
  setAuthInfo,
}: ProviderProps) {
  const { t } = useI18n();
  const { updateUser } = useAuth();
  const { instance } = useMsal();
  const [microsoftAccessToken, setMicrosoftAccessToken] = useState<
    string | undefined
  >(undefined);
  const { login, error, loginResponse } =
    useMicrosoftLogin(microsoftAccessToken);

  useEffect(() => {
    if (microsoftAccessToken) {
      login();
    }
  }, [microsoftAccessToken]);

  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.users?.length) {
        if (loginResponse.users.length > 1) {
          setOpenProfileChoice(true);
          setAuthInfo(loginResponse);
        } else {
          updateUser(
            loginResponse.users[0],
            loginResponse.access_token,
            loginResponse.refresh_token,
            null
          );
        }
      }
    }
  }, [loginResponse]);

  useEffect(() => {
    if (error.isError) {
      showNotification({
        id: 'microsoft-login-error',
        title: t('w.authentificationError'),
        message: error.details.response.data.error_description,
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [error]);

  function onLoginClick() {
    instance
      .loginPopup(loginRequest)
      .then((result) => {
        if (result?.account) {
          requestProfileData(result.account);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function requestProfileData(account: AccountInfo) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .then((response) => {
        callMsGraph(response.accessToken).then(() => {
          setMicrosoftAccessToken(response.accessToken);
        });
      });
  }

  async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
      method: 'GET',
      headers: headers,
    };

    return fetch('https://graph.microsoft.com/v1.0/me', options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  return (
    <Button
      variant="default"
      styles={() => ({
        root: {
          borderRadius: 100,
          width: 300,
          height: 42,
          padding: 0,
        },
        label: {
          fontWeight: 'bold',
        },
      })}
      leftSection={<MicrosoftIcon />}
      onClick={onLoginClick}
    >
      {t('login.with.microsoft')}
    </Button>
  );
}
