import { Group, Select } from '@mantine/core';
import { useMemo } from 'react';

import LabelText from '../../../components/LabelText/LabelText';
import { useI18n } from '../../../contexts/I18nProvider';
import {
  BF,
  BI,
  BJ,
  CD,
  CF,
  CG,
  CI,
  CM,
  DJ,
  DZ,
  FR,
  GA,
  GN,
  GQ,
  KM,
  MA,
  MG,
  ML,
  MR,
  MU,
  NE,
  RW,
  SC,
  SN,
  TD,
  TG,
  TN,
} from '../../../core/CountryEnum';

const countries = [
  FR,
  CI,
  MU,
  SN,
  CM,
  CD,
  CG,
  BJ,
  ML,
  BF,
  TN,
  DZ,
  GN,
  GQ,
  MA,
  MR,
  NE,
  TG,
  BI,
  GA,
  CF,
  TD,
  RW,
  KM,
  DJ,
  MG,
  SC,
];

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
  label: string;
  required?: boolean;
  disabled: boolean;
};

export default function CountriesSelect({
  value,
  onChange,
  label,
  required,
  disabled,
}: Props) {
  const { t } = useI18n();
  const countriesData = useMemo(
    () =>
      countries.map((country) => {
        const urlImg = `/images/flags/${country.toLowerCase()}.png`;
        return {
          image: urlImg,
          label: t(`countriesEnum.${country.toLowerCase()}`),
          value: country,
        };
      }),
    []
  );
  const flagSrc = useMemo(() => {
    if (value) {
      return `/images/flags/${countries
        .find((country) => country.toLowerCase() === value.toLowerCase())
        ?.toLowerCase()}.png`;
    }
  }, [value]);

  return (
    <Select
      label={
        <Group gap="xs">
          <LabelText text={label} />
          <img
            height={15}
            src={flagSrc}
            alt={t(`countriesEnum.${value?.toLowerCase()}`)}
          />
        </Group>
      }
      placeholder={t('w.selectYourCountry')}
      value={value}
      onChange={onChange}
      data={countriesData}
      searchable
      maxDropdownHeight={400}
      nothingFoundMessage={t('w.noCountry')}
      required={required}
      disabled={disabled}
    />
  );
}
