import { Button, Group, LoadingOverlay, Space, Title } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowLeft,
  IconCircleArrowRight,
  IconCopyCheck,
  IconDeviceFloppy,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import SensitiveInfoStepper from '../../../../components/SensitiveInfoStepper';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ProfileService from '../../../../services/ProfileService';
import type {
  User,
  UserPersonalInfo,
  UserPersonalSensitive,
} from '../../../../types/types';

type Props = {
  onClose: () => void;
  employee: User | null;
  refetch: () => void;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
  setActive: (number: number) => void;
  active: number;
  setSensitiveInfo: (data: UserPersonalSensitive) => void;
  refetchUserSensitiveInfo?: () => void;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
  }) => void;
  refetchOBInfos?: () => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
};

interface FormValues {
  id?: string | null;
  userId?: string;
  identityCardNumber?: string;
  identityCardFiles?: FileWithPath[];
  ssNumber?: string;
  ssFiles?: FileWithPath[];
  handicappedWorker?: boolean;
  iban?: string;
  bic?: string;
  residencePermitNumber?: string;
  residencePermitFiles?: FileWithPath[];
}

export default function AddEmployeeSensitiveForm({
  employee,
  refetch,
  onClose,
  userPersonalInfo,
  userSensitiveInfo,
  setActive,
  active,
  setSensitiveInfo,
  refetchUserSensitiveInfo,
  validateEmployeeOnBoarding,
  refetchOBInfos,
  finalValidateEmployeeOnBoarding,
}: Props) {
  const { t } = useI18n();
  const { user, access_token } = useAuth();
  const [identityCardFile, setIdentityCardFile] = useState<FileWithPath[]>([]);
  const [ssFile, setSSFile] = useState<FileWithPath[]>([]);
  const [residencePermitFile, setResidencePermitFile] = useState<
    FileWithPath[]
  >([]);

  const form = useForm<FormValues>({
    initialValues: {
      id:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.id
            ? userSensitiveInfo.id
            : null
          : null,
      userId: employee?.id,
      identityCardNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.identityCardNumber
            ? userSensitiveInfo.identityCardNumber
            : ''
          : '',
      identityCardFiles: [],
      ssNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.ssNumber
            ? userSensitiveInfo.ssNumber
            : ''
          : '',
      ssFiles: [],
      handicappedWorker:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.handicappedWorker
            ? userSensitiveInfo.handicappedWorker
            : false
          : false,
      iban:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.iban
            ? userSensitiveInfo.iban
            : ''
          : '',
      bic:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.bic
            ? userSensitiveInfo.bic
            : ''
          : '',
      residencePermitNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.residencePermitNumber
            ? userSensitiveInfo.residencePermitNumber
            : ''
          : '',
      residencePermitFiles: [],
    },
  });

  const {
    mutate: createOrUpdateUserSensitiveInfo,
    isLoading: isLoadingCreateOrUpdateUserSensitiveInfo,
  } = useMutation({
    mutationFn: (variables: FormValues) =>
      ProfileService.createOrUpdateUserSensitiveInfo(
        employee?.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      if (residencePermitFile.length > 0) {
        updateResidentPermit();
      }
      if (ssFile.length > 0) {
        updateSocialSecurity();
      }
      if (identityCardFile.length > 0) {
        updateIdentityCard();
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setSensitiveInfo(data);
      if (refetchUserSensitiveInfo) {
        refetchUserSensitiveInfo();
      }
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateResidentPermit } = useMutation({
    mutationFn: () =>
      ProfileService.updateResidencePermit(
        employee?.id,
        residencePermitFile[0],
        access_token
      ),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-resident-permit-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateSocialSecurity } = useMutation({
    mutationFn: () =>
      ProfileService.updateSecuritySocial(
        employee?.id,
        ssFile[0],
        access_token
      ),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-security-social-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateIdentityCard } = useMutation({
    mutationFn: () =>
      ProfileService.updateIdentityCard(
        employee?.id,
        identityCardFile[0],
        access_token
      ),
    onSuccess: () => {
      showNotification({
        id: 'udpate-added-employee-indentity-card-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleSensitiveSubmitForm(values: FormValues) {
    if (values.id !== null) {
      createOrUpdateUserSensitiveInfo({
        id: values.id,
        userId: values.userId,
        identityCardNumber: values.identityCardNumber,
        ssNumber: values.ssNumber,
        handicappedWorker: values.handicappedWorker,
        iban: values.iban,
        bic: values.bic,
        residencePermitNumber: values.residencePermitNumber,
      });
    } else {
      createOrUpdateUserSensitiveInfo({
        userId: values.userId,
        identityCardNumber: values.identityCardNumber,
        ssNumber: values.ssNumber,
        handicappedWorker: values.handicappedWorker,
        iban: values.iban,
        bic: values.bic,
        residencePermitNumber: values.residencePermitNumber,
      });
    }

    if (
      values.identityCardFiles != null &&
      values.identityCardFiles?.length > 0
    ) {
      setIdentityCardFile(values.identityCardFiles);
    }
    if (values.ssFiles != null && values.ssFiles?.length > 0) {
      setSSFile(values.ssFiles);
    }
    if (
      values.residencePermitFiles != null &&
      values.residencePermitFiles?.length > 0
    ) {
      setResidencePermitFile(values.residencePermitFiles);
    }
  }

  function openConfirmOnBoarding(values: FormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === employee?.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : employee?.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {'Vous allez finaliser la pré-embauche de votre collaborateur'}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: FormValues) {
    if (employee !== null) {
      if (user.id !== employee.id) {
        if (
          employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: employee.id });
        } else {
          validateEmployeeOnBoarding({ userId: employee.id, employee: false });
        }
      } else {
        validateEmployeeOnBoarding({ userId: employee.id, employee: true });
      }
      handleSensitiveSubmitForm(values);
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleSensitiveSubmitForm(values))}
    >
      <LoadingOverlay visible={isLoadingCreateOrUpdateUserSensitiveInfo} />
      <Title order={3}>{t('w.administrativeInfo')}</Title>
      <Space h={'xl'} />
      <SensitiveInfoStepper
        form={form}
        request={true}
        edit={false}
        userPersonalInfo={userPersonalInfo}
        employee={employee}
      />
      <Group justify={'flex-end'} mt={'51px'} gap={'30px'}>
        {employee && Boolean(employee?.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={
              form.values.identityCardNumber === null ||
              form.values.identityCardNumber === '' ||
              form.values.ssNumber === null ||
              form.values.ssNumber === '' ||
              (employee?.id !== user.id &&
                employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                  null &&
                employee?.onBoarding?.onBoardingCompany?.validationDate
                  ?.dateOfUpdate === null) ||
              userPersonalInfo?.address === null ||
              userPersonalInfo?.birthPlace === null ||
              userPersonalInfo?.birthDepartment === null ||
              userPersonalInfo?.birthday === null ||
              (employee?.id === user.id &&
                employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                  null)
            }
          >
            {employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
            employee?.onBoarding?.onBoardingCompany !== null &&
            employee?.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? "Finalisation de l'embauche"
              : 'Valider les informations'}
          </Button>
        )}
        <Button
          disabled={
            !Boolean(employee) ||
            (Boolean(employee?.onBoardingId) &&
              employee?.id === user.id &&
              employee?.onBoarding?.onBoardingEmployee?.validationDate !== null)
          }
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
          variant={'outline'}
        >
          {t('w.back')}
        </Button>
        <Button
          onClick={() => setActive(active + 1)}
          leftSection={<IconCircleArrowRight />}
          variant={'outline'}
          type={'submit'}
        >
          {t('w.next')}
        </Button>

        <Button
          onClick={onClose}
          variant={'outline'}
          disabled={Boolean(employee?.onBoardingId) && employee?.id === user.id}
        >
          {t('w.close')}
        </Button>
      </Group>
    </form>
  );
}
