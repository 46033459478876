import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import CompanyService from '../services/CompanyService';
import DivisionService from '../services/DivisionService';
import SecurityRoleService from '../services/SecurityRoleService';

export default function useFetchUsers(companyId: string) {
  const {
    data: divisions,
    isLoading: divisionsIsLoading,
    refetch: refetchDivisions,
  } = useQuery({
    queryKey: ['DivisionService.list', companyId],
    queryFn: () => DivisionService.list(companyId),
  });

  const {
    data: roles,
    isLoading: rolesIsLoading,
    refetch: refetchRoles,
  } = useQuery({
    queryKey: ['SecurityRoleService.list', companyId],
    queryFn: () => SecurityRoleService.list(companyId),
  });

  const {
    data: employees,
    isLoading: employeesIsLoading,
    isFetching: employeesIsFetching,
    refetch: refetchEmployees,
  } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'firstname',
        'lastname',
        'lastLoginDate',
        'securityRoleId',
        'divisionId',
        'companyId',
        'valid',
        'mail',
        'employment',
        'identifiantsCompany',
        'vacationCounter',
        'paidFeatures',
        'fixedPriceManager',
        'onBoardingId',
        'onBoarding',
      ]),
  });

  const users = useMemo(() => {
    if (!employees || !roles || !divisions) {
      return [];
    }

    return employees.map((employee: any) => {
      employee.role = roles.find(
        (_elt: any) => _elt.id === employee.securityRoleId
      );
      employee.division = divisions.find(
        (_elt: any) => _elt.id === employee.divisionId
      );
      return employee;
    });
  }, [employees, roles, divisions]);

  const loading: boolean =
    divisionsIsLoading ||
    rolesIsLoading ||
    employeesIsLoading ||
    employeesIsFetching;

  function refetchUsers() {
    refetchDivisions();
    refetchRoles();
    refetchEmployees();
  }

  return { users, divisions, roles, loading, refetchUsers };
}
