import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import useFetchUsers from '../../../hooks/useFetchUsers';
import ProfileService from '../../../services/ProfileService';
import AddEmployeeSteppers from './components/AddEmployeeSteppers/AddEmployeeSteppers';

export default function EmployeeOnBoarding() {
  const { user, access_token } = useAuth();
  const { divisions, roles } = useFetchUsers(user.company.id);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: userContractualInfo, refetch: refetchUserContractualInfo } =
    useQuery({
      queryKey: ['ProfileService.getUserContractualInfo', user.id],
      queryFn: () => ProfileService.getUserContractualInfo(user.id),
    });
  const { data: userPersonalFamily, refetch: refetchUserFamilySituation } =
    useQuery({
      queryKey: ['ProfileService.getUserPersonalFamilySituation', user.id],
      queryFn: () => ProfileService.getUserPersonalFamilySituation(user.id),
    });
  const { data: userPersonalInfo, refetch: refetchUserPersonalInfo } = useQuery(
    {
      queryKey: ['ProfileService.getUserPersonalInfo', user.id],
      queryFn: () => ProfileService.getUserPersonalInfo(user.id),
    }
  );
  const { data: userSensitiveInfo, refetch: refetchUserSensitiveInfo } =
    useQuery({
      queryKey: ['ProfileService.getUserSensitiveInfo', user.id],
      queryFn: () => ProfileService.getUserSensitiveInfo(user.id),
    });
  const { data: userEmergencyContact, refetch: refetchUserEmergencyContact } =
    useQuery({
      queryKey: ['ProfileService.getUserEmergencyContact', user.id],
      queryFn: () => ProfileService.getUserEmergencyContact(user.id),
    });
  const { data: userProfile } = useQuery({
    queryKey: ['ProfileService.getUserProfile', user.id],
    queryFn: () => ProfileService.getUserProfile(access_token, user.id),
    onError: () => navigate('/'),
  });

  function refetchUserProfile() {
    queryClient.invalidateQueries({
      queryKey: ['ProfileService.getUserProfile', user.id],
    });
  }

  return (
    <>
      {userProfile && (
        <AddEmployeeSteppers
          divisions={divisions ? divisions : []}
          roles={roles ? roles : []}
          onClose={() => undefined}
          refetch={refetchUserProfile}
          user={userProfile}
          userPersonalInfo={userPersonalInfo}
          userSensitiveInfo={userSensitiveInfo}
          userContractualInfo={userContractualInfo}
          userFamilySituation={userPersonalFamily}
          userEmergencyContact={userEmergencyContact}
          refetchUserEmergencyContact={refetchUserEmergencyContact}
          refetchUserPersonalInfo={refetchUserPersonalInfo}
          refetchUserSensitiveInfo={refetchUserSensitiveInfo}
          refetchUserFamilySituation={refetchUserFamilySituation}
          refetchUserContractualInfo={refetchUserContractualInfo}
        />
      )}
    </>
  );
}
