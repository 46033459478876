import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import { initPickerDate } from '../../../utils/initPickerDate';
import AccountantCustomersEndOfMonthTable from './components/AccountantCustomersEndOfMonthTable';

export default function AccountantCustomerEndOfMonth() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(initPickerDate());

  const actionButtons = useMemo(
    () => [
      <CustomMonthPickerInput
        key={'month-picker-end-of-month-customers'}
        value={selectedDate}
        onChange={setSelectedDate}
      />,
    ],
    [selectedDate]
  );

  const {
    data: customersListData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.accountantCustomersList',
      companyId,
      // @ts-ignore
      dayjs(selectedDate).format('MM-YYYY'),
    ],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(
        companyId,
        // @ts-ignore
        dayjs(selectedDate).format('MM-YYYY')
      ),
  });

  useEffect(() => {
    if (selectedDate) {
      refetch();
    }
  }, [selectedDate]);

  const rows = useMemo(() => {
    if (!customersListData || !user.myCompanies?.length) {
      return [];
    }

    return customersListData.filter((item) =>
      user.myCompanies?.includes(item.clientCompanyId)
    );
  }, [customersListData, user]);

  return (
    <ScreenWrapper
      title={`${t('w.endOfMonth')} ${t('w.customers').toLowerCase()}`}
      actionButtons={actionButtons}
    >
      <AccountantCustomersEndOfMonthTable
        selectedDate={selectedDate}
        rows={rows.filter(
          (customer) =>
            !!customer.divisionAccountant.length ||
            !!customer.generalManagers.length
        )}
        isLoading={isLoading || isFetching}
      />
    </ScreenWrapper>
  );
}
