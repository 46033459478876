import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppBarProvider from '../contexts/AppBarProvider';
import AuthProvider from '../contexts/AuthProvider';
import I18nProvider from '../contexts/I18nProvider';
import MicrosoftProvider from '../contexts/MicrosoftProvider';
import ModuleProvider from '../contexts/ModuleProvider';
import ThemeProvider from '../contexts/ThemeProvider';
import {
  APP_FAVICON,
  APP_NAME,
  UNDER_MAINTENANCE,
} from '../variables/GlobalVariables';
import Router from './Router';
import UnderMaintenance from './UnderMaintenance/UnderMaintenance';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  useEffect(() => {
    try {
      // @ts-ignore
      document.getElementsByTagName('LINK')[0].href = APP_FAVICON;
      // @ts-ignore
      document.getElementsByTagName('LINK')[1].href = APP_FAVICON;
      // @ts-ignore
      document.getElementsByTagName('META')[6].content = APP_NAME;
      document.title = APP_NAME;
    } catch (ignored) {
      //ignored
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MicrosoftProvider>
          <AuthProvider>
            <ModuleProvider>
              <I18nProvider>
                <ThemeProvider>
                  <Notifications limit={5} />

                  <ModalsProvider
                    modalProps={{
                      transitionProps: {
                        transition: 'slide-up',
                        duration: 300,
                      },
                      centered: true,
                    }}
                  >
                    <AppBarProvider>
                      {UNDER_MAINTENANCE ? <UnderMaintenance /> : <Router />}
                    </AppBarProvider>
                  </ModalsProvider>
                </ThemeProvider>
              </I18nProvider>
            </ModuleProvider>
          </AuthProvider>
        </MicrosoftProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
